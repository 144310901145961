import React from "react";
import { Link, useNavigate } from "react-router-dom";
import CommonPart from "../../../components/CommonPart";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { reactiveAccount } from "../../../services/apis/authentication";
import TextInput from "../../../components/common/TextInput";
import Button from "../../../components/common/Button";
import { useModal } from "../../../contexts/ModelContext";
import SelectList from "../../../components/common/SelectList";
import { Role } from "../../../constant/dataConstant";
const text = "Very Good work are waiting for you Login Now !!!";

function ReactiveAccount() {
  const { showModal } = useModal();
  const navigate = useNavigate();
  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const handleSubmit = async (formData, { resetForm }) => {
    try {
      const data = await reactiveAccount(formData);
      const navigateToLogin = () => {
        if (data.success) {
          navigate("/login");
        }
      };

      showModal(data, navigateToLogin);
      resetForm();
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <div className="md:flex h-full min-h-screen">
      <CommonPart text={text} />
      <div className="flex justify-center items-center md:mx-auto py-[50px] px-[27px] md:w-1/2 h-screen overflow-auto">
        <div className="lg:max-w-[450px] xl:max-w-[642px] w-full bg-white shadow-[0px_0px_12px_#5c5ca133] mx-auto">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              isSubmitting,
              resetForm,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form>
                <div className="px-[20px] md:px-[20px] lg:px-[26px] xl:px-[56px] py-[34px]">
                  <h1 className="text-[25px] md:text-[28px] lg:text-[35px] xl:text-[45px] font-bold text-center pb-[18px]">
                    Reactivate Account
                  </h1>
                  <div>
                    {/* <SelectList
                      label={"Select Role"}
                      options={Role}
                      value={values.role}
                      onChange={(e) => {
                        setFieldValue("role", e);
                      }}
                      name="role"
                      placeholder="Select Role"
                      error={errors.role && touched.role}
                    /> */}
                    <TextInput
                      label="Email Address"
                      name="email"
                      type="email"
                      placeholder="Username or email address"
                      isRequired={true}
                    />
                  </div>
                  <div className="sm:pt-[52px] pt-[37px]">
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      text="Submit"
                      className="bg-primary text-white hover:bg-[#466cb7] w-full"
                    />
                  </div>
                  <div className="pt-[20px]">
                    <Button
                      onClick={() => resetForm()}
                      text="Cancel"
                      className="border-primary border-[2px] text-primary w-full"
                    />
                  </div>
                  <div className="pt-[32px] text-center">
                    <p className="sm:text-[18px] text-[14px] text-gray1 pb-[8px]">
                      Have an account ?
                      <span className="sm:text-[18px] text-[14px] text-primary">
                        {" "}
                        <Link to="/login">&nbsp;Log In</Link>
                      </span>
                    </p>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ReactiveAccount;
