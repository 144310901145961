import React from "react";

const MyCalendar = () => {
  const daysOfWeek = [
    "Monday 01",
    "Tuesday 02",
    "Wednesday 03",
    "Thursday 04",
    "Friday 05",
    "Saturday 06",
    "Sunday 07",
  ];
  const timeSlots = ["10", "11", "12", "13", "14", "15", "16", "17", "18"];

  return (
    <div className="">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="grid grid-cols-8 border-b border-gray-200">
          <div className="p-4 text-center bg-gray-50 font-semibold text-gray-600 border-r border-gray-200">
            <span className="block">🕒</span>
          </div>
          {daysOfWeek.map((day) => (
            <div
              key={day}
              className="p-4 text-center bg-gray-50 font-semibold text-gray-600 border-r border-gray-200"
            >
              {day}
            </div>
          ))}
        </div>

        <div className="grid grid-cols-8 divide-x divide-gray-200">
          <div className="border-r border-gray-200">
            {timeSlots.map((time, index) => (
              <div
                key={index}
                className="h-16 flex items-center justify-center border-b border-gray-200 text-gray-500"
              >
                {time}
              </div>
            ))}
          </div>

          {daysOfWeek.map((_, dayIndex) => (
            <div
              key={dayIndex}
              className="border-r last:border-r-0 border-gray-200"
            >
              {timeSlots.map((_, timeIndex) => (
                <div
                  key={timeIndex}
                  className="h-16 border-b border-gray-200"
                ></div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyCalendar;
