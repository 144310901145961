import React from "react";
import AdminNavbar from "../../components/AdminNavbar";
import AdminSidebar from "../../components/AdminSidebar";

function AdminDashboard() {
  return (
    <>
      <div className="flex bg-gray1">
        <AdminSidebar page="dashboard" />
        <div className="w-full">
          <AdminNavbar page="Dashboard" />
          <div>Dashboard</div>
        </div>
      </div>
    </>
  );
}

export default AdminDashboard;
