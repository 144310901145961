import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CommonPart from "../../../components/CommonPart";
import Button from "../../../components/common/Button";
import { useModal } from "../../../contexts/ModelContext";
import { signup } from "../../../services/apis/authentication";

const text = "Very Good work are waiting for you Login Now !!!";

const VerifyOtp = () => {
  const { showModal } = useModal();
  const navigate = useNavigate();
  const location = useLocation();
  const { firstName, lastName, email, contactNumber, password, role } =
    location.state || {};

  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault();

      inputRefs[index].current.value = "";

      if (index > 0) {
        const prevInput = inputRefs[index - 1].current;
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  };

  const handleInput = (e, index, setFieldValue, values) => {
    const value = e.target.value;

    if (!/^\d*$/.test(value)) {
      return;
    }

    const newOtp = value.slice(0, 1);
    setFieldValue(
      "otp",
      values.otp.substring(0, index) + newOtp + values.otp.substring(index + 1)
    );

    if (value && index < 3) {
      const nextInput = inputRefs[index + 1].current;
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handlePaste = (e, setFieldValue) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").trim();

    if (!/^\d+$/.test(pastedData)) return;

    const otpValue = pastedData.slice(0, 4);
    setFieldValue("otp", otpValue.padEnd(4, ""));

    otpValue.split("").forEach((digit, index) => {
      if (inputRefs[index].current) {
        inputRefs[index].current.value = digit;
      }
    });

    const focusIndex = Math.min(otpValue.length, 3);
    inputRefs[focusIndex].current?.focus();
  };

  const handleOtpSubmit = async (values, { setSubmitting }) => {
    try {
      const formData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        role: role,
        password: password,
        otp: values.otp,
        contactNumber: contactNumber,
      };
      const response = await signup(formData);
      if (response) {
        if (response.success) {
          localStorage.setItem("authToken", response.token);
          localStorage.setItem("role", response.role);
        }
        const navigateToRoleBasedPage = () => {
          if (response.success) {
            if (response.role === "admin") {
              navigate("/admin/dashboard");
            } else if (response.role === "teacher") {
              navigate("/teacher/profile");
            } else {
              navigate("/student/profile");
            }
          }
        };

        showModal(response, navigateToRoleBasedPage);
      }
    } catch (error) {
      console.error("Error verifying OTP", error);
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    otp: Yup.string()
      .matches(/^\d{4}$/, "Please enter a valid 4-digit OTP")
      .required("OTP is required"),
  });

  return (
    <div className="md:flex h-full min-h-screen">
      <CommonPart text={text} />
      <div className="flex justify-center items-center md:mx-auto py-[50px] px-[27px] md:w-1/2 h-screen overflow-auto">
        <div className="lg:max-w-[450px] xl:max-w-[642px] w-full bg-white shadow-[0px_0px_12px_#5c5ca133] mx-auto">
          <Formik
            initialValues={{ otp: "" }}
            validationSchema={validationSchema}
            onSubmit={handleOtpSubmit}
          >
            {({
              isSubmitting,
              resetForm,
              errors,
              touched,
              setFieldValue,
              values,
            }) => (
              <Form>
                <div className="px-[20px] md:px-[20px] lg:px-[26px] xl:px-[56px] py-[34px]">
                  <h1 className="text-[25px] md:text-[28px] lg:text-[35px] xl:text-[45px] font-bold text-center pb-[18px]">
                    Verify OTP
                  </h1>
                  <div className="flex justify-between gap-4 pt-[20px]">
                    {[0, 1, 2, 3].map((index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength="1"
                        className="w-16 h-16 text-center text-2xl border rounded-lg focus:border-primary focus:ring-1 focus:ring-primary"
                        ref={inputRefs[index]}
                        value={values.otp[index] || ""}
                        onChange={(e) =>
                          handleInput(e, index, setFieldValue, values)
                        }
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onPaste={(e) => handlePaste(e, setFieldValue)}
                        placeholder="_"
                      />
                    ))}
                  </div>
                  <div className="sm:pt-[52px] pt-[37px]">
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      text="Submit"
                      className="bg-primary text-white hover:bg-[#466cb7] w-full"
                    />
                  </div>
                  <div className="pt-[20px]">
                    <Button
                      onClick={() => resetForm()}
                      text="Cancel"
                      className="border-primary border-[2px] text-primary w-full"
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
