import React, { useState } from "react";
import CommonPart from "../../../components/CommonPart";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { sendOtp } from "../../../services/apis/authentication";
import SelectList from "../../../components/common/SelectList";
import { Role } from "../../../constant/dataConstant";
import TextInput from "../../../components/common/TextInput";
import Button from "../../../components/common/Button";
import PhoneNumberInput from "../../../components/common/PhoneNumberInput";
import { useModal } from "../../../contexts/ModelContext";

const text = "Your future is created by what  you do today, not tomorrow.";

function Signup() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { showModal } = useModal();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    password: "",
    confirmPassword: "",
    role: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    role: Yup.string().required("Role is required"),
    contactNumber: Yup.string()
      .required("Contact number is required")
      .matches(/^[0-9]+$/, "Contact number must be numeric")
      .min(10, "Contact number must be at least 10 digits")
      .max(15, "Contact number must be at most 15 digits"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSubmit = async (formData) => {
    try {
      const response = await sendOtp({
        email: formData.email,
        contactNumber: formData.contactNumber,
      });

      if (response) {
        const navigateToRoleBasedPage = () => {
          if (response.success) {
            navigate("/verify-otp", { state: formData });
          }
        };

        showModal(response, navigateToRoleBasedPage);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <div className="md:flex h-full min-h-screen">
      <CommonPart text={text} />
      <div className="md:mx-auto py-[50px] px-[27px] md:w-1/2 h-screen overflow-auto">
        <div className="flex justify-center items-center  lg:max-w-[450px] xl:max-w-[642px] w-full m-auto ">
          <div className="w-full bg-white shadow-[0px_0px_12px_#5c5ca133]">
            <div className="px-[20px] md:px-[20px] lg:px-[26px] xl:px-[56px] py-[34px]">
              <h1 className="sm:text-[38px] text-[26px] font-bold text-center pb-[18px]">
                Create Account
              </h1>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  isSubmitting,
                  setFieldValue,
                  resetForm,
                  errors,
                  touched,
                }) => (
                  <Form>
                    <div className="pt-[25px]">
                      <TextInput
                        label="First Name"
                        name="firstName"
                        placeholder="First Name"
                        isRequired={true}
                      />
                    </div>

                    <div className="pt-[18px]">
                      <TextInput
                        label="Last Name"
                        name="lastName"
                        placeholder="Last Name"
                        isRequired={true}
                      />
                    </div>

                    <div className="pt-[18px]">
                      <TextInput
                        label="Email Address"
                        name="email"
                        type="email"
                        placeholder="Username or email address"
                        isRequired={true}
                      />
                    </div>

                    <div className="pt-[18px] w-full">
                      <SelectList
                        label={"Select Role"}
                        options={Role}
                        value={values.role}
                        onChange={(e) => {
                          setFieldValue("role", e);
                        }}
                        name="role"
                        placeholder="Select Role"
                        error={errors.role && touched.role}
                        isRequired={true}
                      />
                    </div>

                    <div className="pt-[18px]">
                      <PhoneNumberInput
                        label="Contact Number"
                        name="contactNumber"
                        value={values.contactNumber}
                        onChange={setFieldValue}
                        error={errors.contactNumber && touched.contactNumber}
                        isRequired={true}
                      />
                    </div>

                    <div className="pt-[18px]">
                      <TextInput
                        label="Password"
                        name="password"
                        type="password"
                        placeholder="Password"
                        showPassword={showPassword}
                        togglePasswordVisibility={togglePasswordVisibility}
                        isRequired={true}
                      />
                    </div>

                    <div className="pt-[18px]">
                      <TextInput
                        label="Confirm Password"
                        name="confirmPassword"
                        type="password"
                        placeholder="Confirm Password"
                        showPassword={showConfirmPassword}
                        togglePasswordVisibility={
                          toggleConfirmPasswordVisibility
                        }
                        isRequired={true}
                      />
                    </div>

                    <div className="pt-[52px]">
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        text="Create Account"
                        className="bg-primary text-white hover:bg-[#466cb7] w-full"
                      />
                    </div>

                    <div className="pt-[20px]">
                      <Button
                        onClick={() => resetForm()}
                        text="Cancel"
                        className="border-primary border-[2px] text-primary w-full"
                      />
                    </div>
                  </Form>
                )}
              </Formik>

              <div className="pt-[32px] text-center">
                <p className="text-[14px] md:text-[16px] lg:text-[18px] text-gray1 pb-[8px]">
                  Have an account ?
                  <span className="text-[14px] md:text-[16px] lg:text-[18px] text-primary">
                    <Link to="/login">&nbsp;Log In</Link>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
