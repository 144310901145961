/* eslint-disable react-hooks/rules-of-hooks */

// React and related libraries
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Form handling and validation
import { Form, Field, Formik, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import "react-phone-input-2/lib/style.css";

// Styled-components
import styled from "styled-components";

// Icons
import { BiSolidCheckCircle } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import { BsCheckAll } from "react-icons/bs";

// API services
import {
  getProfile,
  updateProfile,
} from "../../../services/apis/authentication";

// Redux actions
import {
  updateTeacherProfile,
  updateTeacherSkillMatrix,
} from "../../../redux/teacherProfileSlice";

// Constants and models
import {
  Availability,
  Coupon,
  SkillLevel,
  Source,
  Subscription,
  TeachersTab,
} from "../../../constant/dataConstant";

// handling library
import dayjs from "dayjs";

// Components
import Header from "../../../components/profile/Header";
import Sidebar from "../../../components/profile/Sidebar";
import SelectList from "../../../components/common/SelectList";
import SkillMatrixForm from "../../../components/common/SkillMetricsForm";
import TextInput from "../../../components/common/TextInput";
import Button from "../../../components/common/Button";
import PhoneNumberInput from "../../../components/common/PhoneNumberInput";
import { useModal } from "../../../contexts/ModelContext";
import TimePicker from "../../../components/common/TimeInput";
import UserHeader from "../../../components/header/UserHeader";
import Cropper from "react-cropper";
import { CgDollar } from "react-icons/cg";

const Tab = styled.button`
  ${({ active }) =>
    active &&
    `
    border-bottom: 3px solid #002060;
    opacity: 1;
  `}
`;
const ButtonGroup = styled.div`
  display: flex;
`;
function TeacherProfile() {
  const dispatch = useDispatch();
  const { showModal } = useModal();
  let InitialValues = useSelector((state) => state.teacherProfile);
  const teacherProfile = useSelector((state) => state.teacherProfile);
  const [completedTabs, setCompletedTabs] = useState([]);
  const [profilePicture, setProfilePicture] = useState(null);
  const [active, setActive] = useState(TeachersTab[0]);
  const [showCropper, setShowCropper] = useState(false);
  const [cropper, setCropper] = useState(null);
  const ProfileImage = require("../../../assets/Images/uploadprofile.png");

  const [profileImage, setProfileImage] = useState(
    teacherProfile?.profileImageUrl || ProfileImage
  );

  const getCropData = () => {
    if (cropper) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        const croppedFile = new File([blob], "cropped-image.jpg", {
          type: "image/jpeg",
          lastModified: new Date().getTime(),
        });

        setProfileImage(URL.createObjectURL(blob));
        setProfilePicture(croppedFile);
        setShowCropper(false);
      }, "image/jpeg");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePicture(file);
      const reader = new FileReader();
      reader.onload = () => {
        setProfileImage(reader.result);
        setShowCropper(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlerCallGetAPI = async () => {
    try {
      const response = await getProfile();
      if (response?.user) {
        const formattedStartTime = dayjs(response.user.startTime).format(
          "HH:mm"
        );
        const formattedEndTime = dayjs(response.user.endTime).format("HH:mm");
        dispatch(
          updateTeacherProfile({
            ...teacherProfile,
            ...response.user,
            startTime: formattedStartTime,
            endTime: formattedEndTime,
          })
        );
        setCompletedTabs(TeachersTab);
        if (response.user.profileImageUrl) {
          setProfileImage(response.user.profileImageUrl);
        }
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    if (active === "Personal Settings") {
      handlerCallGetAPI();
    }
  }, []);

  const validateTimeOrder = (start, end) => {
    if (!start || !end) return true;
    const startTime = new Date(`1970-01-01 ${start}`);
    const endTime = new Date(`1970-01-01 ${end}`);
    return startTime < endTime;
  };

  const ValidationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .required("Email is required")
      .min(3, "Email must be at least 3 characters long"),
    contactNumber: Yup.string().required("Contact Number is required"),
    source: Yup.string().required("Source is required"),
    subscription: Yup.string().required("Subscription is required"),
    availability: Yup.string().required("Availability is required"),
    startTime: Yup.string()
      .required("Start time is required")
      .test(
        "is-before-end-time",
        "Start time must be before end time",
        function (value) {
          const { endTime } = this.parent;
          return validateTimeOrder(value, endTime);
        }
      ),
    endTime: Yup.string()
      .required("End time is required")
      .test(
        "is-after-start-time",
        "End time must be after start time",
        function (value) {
          const { startTime } = this.parent;
          return validateTimeOrder(startTime, value);
        }
      ),
    duration: Yup.string().required("Duration is required"),
    couponCode: Yup.string().required("Coupon Code is required"),
    // offeredCouponCode: Yup.string().when("couponCode", {
    //   is: (value) => value === "yes",
    //   then: Yup.string().required("Coupon Code Offered is required"),
    //   otherwise: Yup.string(),
    // }),
    skillMatrix: Yup.array().min(1, "At least one skill is required"),
  });

  const FeeStructureValidation = Yup.object().shape({
    skillMatrix: Yup.array().of(
      Yup.object().shape({
        category: Yup.string().required("Category is required"),
        feePerHour: Yup.string().required("fees is required"),
        skillLevel: Yup.string().required("Skill Level is required"),
      })
    ),
  });

  const handleRemoveSkill = (
    skillToRemove,
    values,
    setFieldValue,
    validateField
  ) => {
    const updatedSkillMatrix = values.skillMatrix.filter(
      ({ category }) => category !== skillToRemove
    );
    setFieldValue("skillMatrix", updatedSkillMatrix).then(() => {
      validateField("skillMatrix");
    });
    dispatch(updateTeacherProfile({ skillMatrix: updatedSkillMatrix }));
  };

  const handleSubmit = async (values) => {
    try {
      if (profilePicture) {
        values.profilePicture = profilePicture;
      }
      dispatch(updateTeacherProfile(values));
      handleTabChange(active, "forward");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmitForm = async (formData) => {
    dispatch(updateTeacherSkillMatrix(formData.skillMatrix));
    try {
      const newFormData = new FormData();
      for (const [key, value] of Object.entries(formData)) {
        if (value !== undefined && value !== null) {
          newFormData.append(key, value);
        }
      }

      if (formData?.skillMatrix && Array.isArray(formData.skillMatrix)) {
        newFormData.append("skillMatrix", JSON.stringify(formData.skillMatrix));
      } else {
      }
      const response = await updateProfile(newFormData);
      if (response) {
        showModal(response);
        handlerCallGetAPI();
        setCompletedTabs(TeachersTab);
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleTabChange = (tab, direction) => {
    if (direction === "forward") {
      if (tab === "Personal Settings") {
        setActive("Skill Matrix");
      } else if (tab === "Skill Matrix") {
        setActive("Fee Structure");
      } else {
        setActive(tab);
      }

      if (!completedTabs.includes(tab)) {
        setCompletedTabs((prevTabs) => [...prevTabs, tab]);
      }
    } else if (direction === "backward") {
      if (tab === "Skill Matrix") {
        setActive("Personal Settings");
      } else if (tab === "Fee Structure") {
        setActive("Skill Matrix");
      }

      setCompletedTabs((prevTabs) => prevTabs.filter((t) => t !== tab));
    }
  };

  const handleAddSkill = (skill, values, setFieldValue, validateField) => {
    if (skill && !values.skillMatrix.some((s) => s.skill === skill)) {
      const newSkill = {
        category: skill,
        subcategory: "",
        skillLevel: "",
      };
      setFieldValue("skillMatrix", [...values.skillMatrix, newSkill]).then(
        () => {
          validateField("skillMatrix");
        }
      );
    }
    setFieldValue("skills", "");
  };

  const handleSubmitskillMatrix = (values) => {
    dispatch(updateTeacherSkillMatrix(values.skillMatrix));
    handleTabChange(active, "forward");
  };

  const calculateDuration = (startTime, endTime) => {
    if (!startTime || !endTime) return "";

    const start = new Date(`1970-01-01 ${startTime}`);
    const end = new Date(`1970-01-01 ${endTime}`);
    const durationInMinutes = (end - start) / (1000 * 60);

    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);

    return `${hours ? `${hours} hour${hours > 1 ? "s" : ""}` : ""}${
      hours && minutes ? " " : ""
    }${minutes ? `${minutes} minute${minutes > 1 ? "s" : ""}` : ""}`.trim();
  };

  return (
    <>
      <div className="!flex bg-gray1 justify-end">
        <Sidebar />
        <div className="lg:!w-[calc(100%-280px)] w-full">
          <UserHeader />
          <div className="md:p-[34px_45px] sm:p-[25px_35px] p-[16px] h-[calc(100%-280px)]">
            <h1 className="text-[30px] font-medium text-nevyblue mb-[21px]">
              Settings
            </h1>

            <div className="overflow-auto">
              <ButtonGroup>
                {TeachersTab?.map((type) => (
                  <Tab
                    key={type}
                    active={active === type}
                    onClick={() => setActive(type)}
                    className="bg-white text-primary flex justify-between items-center gap-[25px] sm:text-[18px] text-[14px] font-medium p-[16px] w-full max-w-[245px] cursor-pointer border-0 outline-0 text-left whitespace-nowrap"
                  >
                    {type}
                    {completedTabs.includes(type) && <BsCheckAll />}{" "}
                  </Tab>
                ))}
              </ButtonGroup>
            </div>

            {active === "Personal Settings" && (
              <div className="bg-white mt-1">
                <div
                  className={`flex justify-between items-center flex-wrap gap-[10px] p-[30px] border-b-[2px] border-b-[#F1F1F1]`}
                >
                  <h1 className="text-[20px] font-bold text-nevyblue">
                    Personal Settings
                  </h1>
                  <div className="flex items-center gap-[5px]">
                    <BiSolidCheckCircle className="text-secondary2" />
                    <p className="text-[14px] text-secondary2 font-normal mb-0">
                      All Updates Saved as Draft
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    <div className="flex lg:!flex-row !flex-col md:p-[30px_30px_0px_30px] p-[16px] md:gap-[50px] xl:gap-[108px] gap-[20px] items-start">
                      <div>
                        <div className="flex flex-col items-center justify-center w-full relative">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer w-[170px] h-[170px] object-cover shadow-[0px_0px_4px_00000042] rounded-[10px] overflow-hidden border-2 border-gray-300 transition"
                          >
                            <img
                              src={profileImage}
                              alt="profile-image"
                              className="w-[170px] h-[170px] rounded-[10px] object-cover"
                            />

                            <input
                              id="file-upload"
                              type="file"
                              className="hidden"
                              onChange={handleFileChange}
                            />
                          </label>

                          {showCropper && profileImage && (
                            <div className="fixed inset-0 bg-transparent bg-opacity-50 flex items-center justify-center z-50">
                              <div className="bg-white p-4 rounded-lg max-w-2xl w-full mx-4">
                                <Cropper
                                  src={profileImage}
                                  style={{ height: 400, width: "100%" }}
                                  aspectRatio={1}
                                  guides={true}
                                  onInitialized={(instance) =>
                                    setCropper(instance)
                                  }
                                />
                                <div className="flex justify-end gap-2 mt-4">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setShowCropper(false);
                                      setProfileImage(null);
                                    }}
                                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    onClick={getCropData}
                                    className="px-4 py-2 bg-primary text-white rounded"
                                  >
                                    Crop & Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <Formik
                        initialValues={teacherProfile}
                        onSubmit={handleSubmit}
                        validationSchema={ValidationSchema}
                        enableReinitialize
                      >
                        {({
                          values,
                          setFieldValue,
                          validateField,
                          errors,
                          touched,
                          resetForm,
                          setTouched,
                          isSubmitting,
                        }) => {
                          // const duration = calculateDuration(
                          //   values.startTime,
                          //   values.endTime
                          // );

                          // if (duration !== values.duration) {
                          //   setFieldValue("duration", duration);
                          // }
                          useEffect(() => {
                            const duration = calculateDuration(
                              values.startTime,
                              values.endTime
                            );
                            setFieldValue("duration", duration);
                          }, [values.startTime, values.endTime, setFieldValue]);
                          return (
                            <Form className="w-full">
                              <div className="w-full">
                                <div className="flex flex-wrap gap-5">
                                  <div className="sm:!w-[calc(50%-10px)] w-full">
                                    <TextInput
                                      label="First Name"
                                      name="firstName"
                                      placeholder="First Name"
                                      isRequired={true}
                                    />
                                  </div>
                                  <div className="sm:!w-[calc(50%-10px)] w-full">
                                    <TextInput
                                      label="Last Name"
                                      name="lastName"
                                      placeholder="Last Name"
                                      isRequired={true}
                                    />
                                  </div>
                                  <div className="sm:!w-[calc(50%-10px)] w-full">
                                    <TextInput
                                      label="Email Address"
                                      name="email"
                                      type="email"
                                      placeholder="Username or email address"
                                      readOnly={true}
                                      isRequired={true}
                                    />
                                  </div>
                                  <div className="sm:!w-[calc(50%-10px)] w-full">
                                    <PhoneNumberInput
                                      label="Contact Number"
                                      name="contactNumber"
                                      value={values.contactNumber}
                                      onChange={setFieldValue}
                                      error={
                                        errors.contactNumber &&
                                        touched.contactNumber
                                      }
                                      isRequired={true}
                                    />
                                  </div>
                                  <div className="sm:!w-[calc(50%-10px)] w-full">
                                    <SelectList
                                      label={"Source"}
                                      options={Source}
                                      value={values.source}
                                      onChange={(e) => {
                                        setFieldValue("source", e);
                                      }}
                                      name="source"
                                      placeholder="Select Source"
                                      error={errors.source && touched.source}
                                      isRequired={true}
                                    />
                                  </div>
                                  <div className="sm:!w-[calc(50%-10px)] w-full">
                                    <SelectList
                                      label={"Subscription"}
                                      options={Subscription}
                                      value={values.subscription}
                                      onChange={(e) => {
                                        setFieldValue("subscription", e);
                                      }}
                                      name="subscription"
                                      placeholder="Select subscription"
                                      error={
                                        errors.subscription &&
                                        touched.subscription
                                      }
                                      isRequired={true}
                                    />
                                  </div>
                                  <div className="sm:!w-[calc(50%-10px)] w-full">
                                    <SelectList
                                      label={"Availability"}
                                      options={Availability}
                                      value={values.availability}
                                      onChange={(e) => {
                                        setFieldValue("availability", e);
                                      }}
                                      name="availability"
                                      placeholder="Select Availability"
                                      error={
                                        errors.availability &&
                                        touched.availability
                                      }
                                      isRequired={true}
                                    />
                                  </div>
                                  <div className="sm:!w-[calc(50%-10px)] w-full">
                                    <TextInput
                                      label="Start Time"
                                      name="startTime"
                                      type="time"
                                      placeholder="Start Time"
                                    />
                                    {/* <TimePicker
                                      label="Start Time"
                                      value={values.startTime}
                                      onChange={(time) => {
                                        setFieldValue("startTime", time);
                                        validateField("startTime");
                                      }}
                                      onBlur={() =>
                                        setTouched({ startTime: true })
                                      }
                                      name="startTime"
                                      placeholder="Start Time"
                                      error={
                                        errors.startTime && touched.startTime
                                      }
                                      isRequired={true}
                                    /> */}
                                  </div>
                                  <div className="sm:!w-[calc(50%-10px)] w-full">
                                    <TextInput
                                      label="End Time"
                                      name="endTime"
                                      type="time"
                                      placeholder="End Time"
                                    />
                                    {/* <TimePicker
                                      label="End Time"
                                      value={values.endTime}
                                      onChange={(time) => {
                                        setFieldValue("endTime", time);
                                        validateField("endTime");
                                      }}
                                      onBlur={() =>
                                        setTouched({ endTime: true })
                                      }
                                      name="endTime"
                                      placeholder="End Time"
                                      error={errors.endTime && touched.endTime}
                                      isRequired={true}
                                    /> */}
                                  </div>
                                  <div className="sm:!w-[calc(50%-10px)] w-full">
                                    <TextInput
                                      label="Duration"
                                      name="duration"
                                      placeholder="Enter Duration"
                                      isRequired={true}
                                    />
                                  </div>
                                </div>
                                <div className="mt-5">
                                  <TextInput
                                    label="Coupon Code"
                                    name="couponCode"
                                    placeholder="Enter your coupon code"
                                    isRequired={true}
                                  />
                                </div>
                                <div className="col-span-2 mt-5">
                                  <label className="text-[16px] font-normal text-black">
                                    Skills
                                    <span className="text-red text-[18px] pl-1">
                                      *
                                    </span>
                                  </label>
                                  <div>
                                    <div className="relative border border-gray-300 rounded-[4px] flex items-center bg-white1 w-full">
                                      <Field
                                        name="skills"
                                        className="w-full p-[12px_16px]  pr-[150px] placeholder:text-[14px] placeholder:text-secondary2 relative focus:outline-none"
                                        placeholder="Select a Skill"
                                      />
                                      <button
                                        type="button"
                                        onClick={() => {
                                          if (values.skills) {
                                            handleAddSkill(
                                              values.skills,
                                              values,
                                              setFieldValue,
                                              validateField
                                            );
                                          }
                                        }}
                                        className="absolute right-[16px] text-[16px] font-medium text-primary"
                                      >
                                        + Add More
                                      </button>
                                    </div>
                                  </div>
                                  <ErrorMessage
                                    name="skillMatrix"
                                    component="div"
                                    className="text-red text-sm"
                                  />
                                  <div className="mt-[17px] flex gap-[12px] items-center flex-wrap">
                                    {values.skillMatrix
                                      .filter((skill) => skill.category !== "")
                                      .map((skill, index) => (
                                        <div
                                          key={index}
                                          className="flex items-center"
                                        >
                                          <button className="text-nevyblue px-[10px] text-[16px] font-medium flex justify-center gap-[10px] items-center border-[1.5px] border-[#273446] rounded-[6px] h-9">
                                            {skill.category}
                                            <MdOutlineCancel
                                              className="text-red w-[16px] h-[16px] cursor-pointer"
                                              onClick={() =>
                                                handleRemoveSkill(
                                                  skill.category,
                                                  values,
                                                  setFieldValue,
                                                  validateField
                                                )
                                              }
                                            />
                                          </button>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>

                              <div className="flex justify-end sm:gap-[22px] gap-[12px] sm:p-[30px] p-[12px]">
                                {" "}
                                <Button
                                  onClick={() => resetForm()}
                                  text="Reset"
                                  className="border-primary border-[2px] text-primary sm:px-[28px] px-[16px]"
                                />
                                <Button
                                  type="submit"
                                  disabled={isSubmitting}
                                  text="Save & Next"
                                  className="bg-primary text-white hover:bg-[#466cb7] sm:px-[28px] px-[16px]"
                                />
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {active === "Skill Matrix" && (
              <div className="bg-white mt-1">
                <div className="flex justify-between items-center flex-wrap gap-[10px] p-[30px] border-b-[2px] border-b-[#F1F1F1]">
                  <h1 className="text-[18px] font-normal text-black">
                    Skill Matrix
                  </h1>

                  <div className="flex items-center gap-[5px]">
                    <BiSolidCheckCircle className="text-secondary2" />
                    <p className="text-[14px] text-secondary2 font-normal mb-0">
                      Skill Matrix All Updates Saved as Draft
                    </p>
                  </div>
                </div>

                <SkillMatrixForm
                  initialValues={teacherProfile}
                  handleSubmit={handleSubmitskillMatrix}
                  handleTabChange={handleTabChange}
                  active={active}
                  role={"teacher"}
                />
              </div>
            )}

            {active === "Fee Structure" && (
              <div className="bg-white mt-1">
                <div className="flex justify-between items-center flex-wrap gap-[10px] p-[30px] border-b-[2px] border-b-[#F1F1F1]">
                  <h1 className={"text-[18px] font-normal text-black"}>
                    Fee Structure
                  </h1>

                  <div className="flex items-center gap-[5px]">
                    <BiSolidCheckCircle className="text-secondary2" />
                    <p className="text-[14px] text-secondary2 font-normal mb-0">
                      All Updates Saved as Draft
                    </p>
                  </div>
                </div>

                <Formik
                  initialValues={{
                    ...InitialValues,
                    skillMatrix: InitialValues.skillMatrix.length
                      ? InitialValues.skillMatrix
                      : [{ category: "", skillLevel: "", feePerHour: "" }],
                  }}
                  onSubmit={(values) => handleSubmitForm(values)}
                  enableReinitialize
                  validationSchema={FeeStructureValidation}
                >
                  {({ values, setFieldValue, errors, touched }) => (
                    <Form>
                      <div className="sm:p-[30px] p-[12px]">
                        <div className=" px-[15px] pb-[15px]">
                          <FieldArray name="skillMatrix">
                            {({ push, remove }) => (
                              <div>
                                {values.skillMatrix?.map((skill, index) => (
                                  <div key={index}>
                                    {/* <div className="text-lightgray1 font-medium pt-[15px]">{`Skill ${
                                      index + 1
                                    }`}</div> */}

                                    <div className="grid md:grid-cols-3 grid-cols-1 gap-[27px] mt-[22px]">
                                      <TextInput
                                        label="Category"
                                        name={`skillMatrix.${index}.category`}
                                        placeholder="Enter Category"
                                        isRequired={true}
                                      />
                                      <div>
                                        <SelectList
                                          label={`Skill Level`}
                                          options={SkillLevel}
                                          value={skill.skillLevel}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `skillMatrix.${index}.skillLevel`,
                                              e
                                            );
                                          }}
                                          name={`skillMatrix.${index}.skillLevel`}
                                          placeholder="Select Skill Level"
                                          error={
                                            errors.skillMatrix?.[index]
                                              ?.skillLevel &&
                                            touched.skillMatrix?.[index]
                                              ?.skillLevel
                                          }
                                          isRequired={true}
                                        />
                                      </div>

                                      <div>
                                        <div className="relative">
                                          <CgDollar className="text-[#5C5CA1] text-[20px] absolute top-[42px] z-10 left-[12px]" />
                                          <TextInput
                                            label="Fee/hr"
                                            name={`skillMatrix.${index}.feePerHour`}
                                            type="number"
                                            placeholder="Course Fee"
                                            isFees={true}
                                            isRequired={true}
                                          />
                                          <p className="text-[12px] font-normal absolute right-[16px] top-[42px]">
                                            USD
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </FieldArray>
                        </div>
                      </div>

                      <div className="flex sm:justify-end  sm:gap-[22px] gap-[12px] sm:p-[30px] p-[12px] pb-8">
                        <Button
                          onClick={() => handleTabChange(active, "backward")}
                          text="Previous"
                          className="border-primary border-[2px] text-primary px-[28px]"
                        />
                        <Button
                          type="submit"
                          text="Submit"
                          className="border-primary border-[2px] text-primary w-full max-w-[224px] hover:bg-primary hover:text-white"
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default TeacherProfile;
