import React, { useState } from "react";
import CommonPart from "../../../components/CommonPart";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { resetPassword } from "../../../services/apis/authentication";
import TextInput from "../../../components/common/TextInput";
import Button from "../../../components/common/Button";
import { useModal } from "../../../contexts/ModelContext";

const text = "Very Good work are waiting for you Login Now !!!";

function ResetPassword() {
  const { token } = useParams();
  const { showModal } = useModal();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const handleSubmit = async (formData) => {
    try {
      const newFormData = { ...formData, token };
      const response = await resetPassword(newFormData);
      if (response) {
        const navigateToRoleBasedPage = () => {
          if (response.success) navigate("/login");
        };

        showModal(response, navigateToRoleBasedPage);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <div className="md:flex h-full min-h-screen w-full">
      <CommonPart text={text} />
      <div className="flex justify-center items-center md:mx-auto py-[50px] px-[27px] md:w-1/2 h-screen overflow-auto">
        <div className="lg:max-w-[450px] xl:max-w-[642px] w-full bg-white shadow-[0px_0px_12px_#5c5ca133] mx-auto ">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ resetForm, isSubmitting }) => (
              <Form>
                <div className="w-full bg-white mx-auto">
                  <div className="px-[20px] md:px-[20px] lg:px-[26px] xl:px-[56px] py-[34px]">
                    <h1 className="text-[25px] md:text-[28px] lg:text-[35px] xl:text-[45px] font-bold text-center pb-[18px]">
                      Reset Password
                    </h1>

                    <div>
                      <TextInput
                        label="New Password"
                        name="newPassword"
                        type="password"
                        placeholder="Password"
                        showPassword={showPassword}
                        togglePasswordVisibility={togglePasswordVisibility}
                        isRequired={true}
                      />
                    </div>

                    <div className="pt-[18px] w-full">
                      <TextInput
                        label="Confirm Password"
                        name="confirmPassword"
                        type="password"
                        placeholder="Confirm Password"
                        showPassword={showConfirmPassword}
                        togglePasswordVisibility={
                          toggleConfirmPasswordVisibility
                        }
                        isRequired={true}
                      />
                    </div>

                    <div className="pt-[52px]">
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        text="Submit"
                        className="bg-primary text-white hover:bg-[#466cb7] w-full"
                      />
                    </div>

                    <div className="pt-[20px]">
                      <Button
                        onClick={() => resetForm()}
                        text="Cancel"
                        className="border-primary border-[2px] text-primary w-full"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
