import React, { useState } from "react";
import { VscClose } from "react-icons/vsc";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import TABLA from "../../assets/Images/tabla.png";

function VideoModal({ open, onClose }) {
  const [fileName, setFileName] = useState("Upload Files");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };

  const handleButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="relative z-10"
      id="exampleModal"
    >
      <DialogBackdrop className="fixed inset-0 bg-[#0000004d]" />
      <div className="fixed inset-0 z-10 flex items-center justify-center p-4">
        {/* <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"> */}
        <DialogPanel className="relative transform overflow-hidden bg-white text-left shadow-xl sm:my-8 w-full max-w-[743px]">
          <div className="flex justify-between items-center sm:p-[20px] p-[11px] border-b border-b-[#E3E2E2]">
            <h1 className="text-[18px] font-normal text-black">Video</h1>
            <VscClose
              className="w-[24px] h-[24px] text-black cursor-pointer"
              onClick={onClose}
            />
          </div>
          <div className="sm:p-[33px_20px] p-[11px_11px_33px_11px]">
            <div>
              <div className="flex items-center border border-[#ABAAAA] h-[56px]">
                <input
                  type="text"
                  placeholder="Upload Files"
                  value={fileName}
                  className="w-full py-2 px-3 text-gray-500 border-none focus:outline-none"
                  readOnly
                />

                <input
                  type="file"
                  id="fileInput"
                  className="hidden"
                  onChange={handleFileChange}
                />

                <button
                  className="bg-lightpurple text-primary sm:text-[16px] text-[14px] font-medium border border-[#ACC4F5] w-[190px] h-[56px]"
                  onClick={handleButtonClick}
                >
                  Upload File
                </button>
              </div>

              <p className="sm:text-[16px] text-[12px] font-medium text-gray3 sm:mt-[34px] mt-[16px] sm:mb-[44px] mb-[16px]">
                <span className="text-black">Note:</span> All files should be at
                least 720p and less than 4.0 GB.
              </p>
            </div>
            <div className="flex items-center gap-[12px]">
              <div>
                <img
                  src={TABLA}
                  alt=""
                  className="sm:w-[130px] w-[94px] sm:h-[130px] h-[94px]"
                />
              </div>
              <div className="flex justify-center gap-[11px] flex-col">
                <h1 className="sm:text-[16px] text-[12px] text-primary font-normal mb-0">
                  File Uploaded{" "}
                  <span className="text-secondary2">• 1:55 Pm</span>
                </h1>
                <p className="sm:text-[16px] text-[12px] font-normal text-nevyblue mb-0">
                  Mastering Tabla: Beginner to Pro Lessons
                </p>
                <h1 className="sm:text-[16px] text-[12px] text-primary font-normal mb-0">
                  Replace Video
                </h1>
              </div>
            </div>
            <div className="flex sm:justify-end justify-center sm:gap-[22px] gap-[12px] sm:mt-[68px] mt-[48px]">
              <button
                type="button"
                className="border-[2px] border-[#002060] text-primary hover:bg-primary w-full max-w-[130px] sm:h-[48px] h-[33px] flex items-center justify-center sm:text-[18px] text-[14px] font-bold hover:text-white rounded-[4px]"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="border-[2px] border-[#002060] text-primary hover:bg-primary w-full max-w-[168px] sm:h-[48px] h-[33px] flex items-center justify-center sm:text-[18px] text-[14px] font-bold hover:text-white rounded-[4px]"
              >
                Upload video
              </button>
            </div>
          </div>
        </DialogPanel>
        {/* </div> */}
      </div>
    </Dialog>
  );
}

export default VideoModal;
