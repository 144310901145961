import React, { useState } from "react";
import FILTER from "../../../assets/Images/Filter.png";
import { Link } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { IoIosArrowDown } from "react-icons/io";
import { FaChevronUp, FaChevronDown } from "react-icons/fa6";
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
  BsCurrencyDollar,
} from "react-icons/bs";

import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import StartModel from "../../../model/StartModel";
import { userData } from "../../../constant/dataConstant";
import AuthHeader from "../../../components/header/AuthHeader";
import { isAuthenticated } from "../../router/ProtectedRoute";
import UserHeader from "../../../components/header/UserHeader";

const language = [
  { id: 1, name: "Trending1" },
  { id: 2, name: "Trending2" },
  { id: 3, name: "Trending3" },
];

function CourseList() {
  const [selectedlanguages, setSelectedLanguages] = useState(language[0]);
  const [isFilter, setIsFilter] = useState(false);
  const [isCategory, setIsCategory] = useState(false);
  const [selected, setSelected] = useState([]);
  const [isDance, setIsDance] = useState(false);
  const [isSinging, setIsSinging] = useState(false);
  const [isTabla, setIsTabla] = useState(false);
  const [isDrums, setIsDrums] = useState(false);
  const [isRating, setIsRating] = useState(false);
  const [isCourse, setIsCourse] = useState(false);
  const [isDuration, setIsDuration] = useState(false);
  const [isPrice, setIsPrice] = useState(false);
  const [value, setValue] = useState(50);
  const [file, setFile] = useState(false);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const dance = [
    { name: "Tap Dance", count: 574 },
    { name: "Ballroom Dance", count: 574 },
    { name: "Latin Dance", count: 1345 },
    { name: "Modern Dance", count: 317 },
    { name: "Lyrical Dance", count: 31 },
    { name: "Breakdance", count: 558 },
    { name: "Swing Dance", count: 37 },
  ];
  const singing = [
    { name: "Tap Dance", count: 574 },
    { name: "Ballroom Dance", count: 574 },
  ];
  const tabla = [
    { name: "Tap Dance", count: 574 },
    { name: "Ballroom Dance", count: 574 },
  ];
  const drums = [
    { name: "Tap Dance", count: 574 },
    { name: "Ballroom Dance", count: 574 },
  ];
  const rating = [
    { star: <AiFillStar />, name: "5 Star", count: 574 },
    { star: <AiFillStar />, name: "4 Star & up", count: 574 },
    { star: <AiFillStar />, name: "3 Star & up", count: 574 },
    { star: <AiFillStar />, name: "2 Star & up", count: 574 },
    { star: <AiFillStar />, name: "1 Star & up", count: 574 },
  ];
  const course = [
    { name: "Tap Dance", count: 574 },
    { name: "Ballroom Dance", count: 574 },
    { name: "Latin Dance", count: 574 },
    { name: "Modern Dance", count: 574 },
  ];
  const duration = [
    { name: "6-12 Months", count: 1345 },
    { name: "3-6 Months", count: 1345 },
    { name: "1-3 Months", count: 1345 },
    { name: "1-4 Weeks", count: 1345 },
    { name: "1-7 Days", count: 1345 },
  ];
  const price = [
    { name: "Paid", count: 1345 },
    { name: "Free", count: 1345 },
  ];

  const toggleAccordion = () => {
    setIsCategory(!isCategory);
  };
  const toggleAccordionRating = () => {
    setIsRating(!isRating);
  };
  const toggleAccordionCourse = () => {
    setIsCourse(!isCourse);
  };
  const toggleAccordionDuration = () => {
    setIsDuration(!isDuration);
  };

  const toggleAccordionDance = () => {
    setIsDance(!isDance);
  };
  const toggleAccordionSinging = () => {
    setIsSinging(!isSinging);
  };
  const toggleAccordionTabla = () => {
    setIsTabla(!isTabla);
  };
  const toggleAccordionDrums = () => {
    setIsDrums(!isDrums);
  };
  const toggleAccordionPrice = () => {
    setIsPrice(!isPrice);
  };

  const handleCheckboxChange = (name) => {
    if (selected.includes(name)) {
      setSelected(selected.filter((item) => item !== name));
    } else {
      setSelected([...selected, name]);
    }
  };
  const handleSelected = (lan) => {
    setSelectedLanguages(lan);
  };

  return (
    <div className="">
      {isAuthenticated() ? <UserHeader /> : <AuthHeader />}
      <div className="md:px-[40px] px-[16px]">
        <div className="py-[31px] border-b border-b-[#ACACAC]">
          <div className="flex items-center justify-between gap-1 md:flex-nowrap flex-wrap">
            <div className="flex flex-col sm:gap-[32px] gap-[18px]">
              <div className="flex items-center sm:justify-between justify-normal sm:gap-[18px] gap-[14px]">
                <button
                  onClick={() => setIsFilter(!isFilter)}
                  className="sm:w-[127px] w-[101px] sm:h-[50px] h-[42px] flex items-center justify-center gap-1 bg-white border border-[#ABAAAA] rounded"
                >
                  <img src={FILTER} alt="" className="w-[37px] h-[37px]" />
                  <h1 className="sm:text-[20px] text-[16px] text-black font-medium ">
                    Filter
                  </h1>
                </button>

                <div className="relative flex items-center border border-[#ACACAC] p-[13px_15px] sm:h-[50px] h-[42px] rounded sm:w-[276px] w-[170px]">
                  <BiSearch className="absolute w-[23px] h-[24px]" />
                  <input
                    className="bg-transparent pl-[30px] w-full min-w-[100px] sm:text-[16px] text-[14px] font-medium text-black placeholder:text-secondary2 transition duration-300 ease focus:outline-none focus:border-slate-400"
                    placeholder="Dance"
                  />
                </div>
              </div>
              <div className="flex items-center sm:gap-[18px] gap-[10px]">
                <h1
                  className="sm:text-[18px] text-[12px] text-nevyblue font-normal mb-0"
                  onClick={() => setFile(true)}
                >
                  Suggestion:
                </h1>
                <p className="sm:text-[18px] text-[12px] text-primary font-normal mb-0">
                  Rhythm
                </p>
                <p className="sm:text-[18px] text-[12px] text-primary font-normal mb-0">
                  Groove
                </p>
                <p className="sm:text-[18px] text-[12px] text-primary font-normal mb-0">
                  Vibe
                </p>
                <p className="sm:text-[18px] text-[12px] text-primary font-normal mb-0">
                  Eclipse
                </p>
                <p className="sm:text-[18px] text-[12px] text-primary font-normal mb-0">
                  Elevate
                </p>
              </div>
            </div>
            <div>
              <StartModel open={file} onClose={() => setFile(false)} />
            </div>
            <div className="flex flex-col sm:gap-[32px] gap-[18px] sm:mt-0 mt-[20px]">
              <div className="flex items-center md:justify-end justify-start gap-[10px]">
                <p className="mb-0 text-[16px] text-secondary2 font-medium">
                  Sort by:
                </p>
                <div>
                  <Listbox
                    className=""
                    value={selectedlanguages}
                    onChange={(e) => {
                      setSelectedLanguages(e);
                    }}
                  >
                    <div className="">
                      <ListboxButton className="relative flex items-center gap-[12px] w-[144px] sm:h-[50px] h-[42px]  justify-center rounded border border-[#ABAAAA] bg-white placeholder:text-[14px] text-nevyblue placeholder:text-nevyblue focus:outline-none bg-transparent">
                        <div
                          className="flex items-center"
                          aria-expanded="true"
                          aria-haspopup="true"
                        >
                          <span className="text-[16px] text-nevyblue font-medium">
                            {selectedlanguages.name}
                          </span>
                        </div>
                        <div className="pointer-events-none inset-y-0 right-[10px]">
                          <IoIosArrowDown className="sm:w-[25px] w-[22px] sm:h-[25px] h-[22px] sm:text-[16px] text-[14px] text-nevyblue font-medium" />
                        </div>
                      </ListboxButton>

                      <ListboxOptions className="absolute z-10 mt-1  w-[144px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {language.map((lan) => (
                          <ListboxOption
                            key={lan.id}
                            value={lan}
                            onClick={() => handleSelected(lan)}
                            role="menuitem"
                            tabIndex="-1"
                            className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-primary data-[focus]:text-white"
                          >
                            {lan.name}
                          </ListboxOption>
                        ))}
                      </ListboxOptions>
                    </div>
                  </Listbox>
                </div>
              </div>
              <div className="flex items-center justify-between md:flex-row flex-col">
                <div className="sm:text-[18px] text-[14px] font-normal text-black">
                  3,145,684 &nbsp;
                  <span className="sm:text-[18px] text-[14px] font-normal text-secondary2">
                    results find for “Dance”
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative">
          {isFilter && (
            <div className="absolute sm:w-[460px] w-[291px] bg-white border-t-0 border border-[#ACACAC]">
              <div>
                <div
                  className="flex justify-between items-center cursor-pointer p-4 border-b border-b-[#ACACAC]"
                  onClick={toggleAccordion}
                >
                  <span className="sm:text-[28px] text-[20px] font-normal text-black">
                    Category
                  </span>

                  <span>
                    {isCategory ? <FaChevronUp /> : <FaChevronDown />}
                  </span>
                </div>
                {isCategory && (
                  <>
                    <div>
                      <div className="p-4 border-b">
                        <div
                          className="flex justify-between items-center cursor-pointer sm:pl-[25px] pl-[18px]"
                          onClick={toggleAccordionDance}
                        >
                          <span className="sm:text-[20px] text-[16px] text-black font-normal">
                            Dance
                          </span>
                          <span>
                            {isDance ? <FaChevronUp /> : <FaChevronDown />}
                          </span>
                        </div>
                        {isDance && (
                          <div className="pt-[16px]">
                            {dance.map((category, index) => (
                              <div
                                key={index}
                                className="flex justify-between items-center mb-2"
                              >
                                <div className="flex items-start gap-[8px]">
                                  <div>
                                    <input
                                      type="checkbox"
                                      className="sm:w-[22px] w-[18px] sm:h-[22px] h-[18px]"
                                      checked={selected.includes(category.name)}
                                      onChange={() =>
                                        handleCheckboxChange(category.name)
                                      }
                                    />
                                  </div>
                                  <div className="sm:text-[18px] text-[14px] text-gray1 font-normal hover:text-primary">
                                    {category.name}
                                  </div>
                                </div>
                                <div className="sm:text-[18px] text-[14px] text-secondary2 font-normal hover:text-gray1">
                                  {category.count}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="p-4 border-b">
                        <div
                          className="flex justify-between items-center cursor-pointer sm:pl-[25px] pl-[18px]"
                          onClick={toggleAccordionSinging}
                        >
                          <span className="sm:text-[20px] text-[16px] text-black font-normal">
                            Singing
                          </span>
                          <span>
                            {isSinging ? <FaChevronUp /> : <FaChevronDown />}
                          </span>
                        </div>
                        {isSinging && (
                          <div className="pt-[16px]">
                            {singing.map((category, index) => (
                              <div
                                key={index}
                                className="flex justify-between items-center mb-2"
                              >
                                <div className="flex items-start gap-[8px]">
                                  <div>
                                    <input
                                      type="checkbox"
                                      className="sm:w-[22px] w-[18px] sm:h-[22px] h-[18px]"
                                      checked={selected.includes(category.name)}
                                      onChange={() =>
                                        handleCheckboxChange(category.name)
                                      }
                                    />
                                  </div>
                                  <div className="sm:text-[18px] text-[14px] text-gray1 font-normal hover:text-primary">
                                    {category.name}
                                  </div>
                                </div>
                                <div className="sm:text-[18px] text-[14px] text-secondary2 font-normal hover:text-gray1">
                                  {category.count}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="p-4 border-b">
                        <div
                          className="flex justify-between items-center cursor-pointer sm:pl-[25px] pl-[18px]"
                          onClick={toggleAccordionTabla}
                        >
                          <span className="sm:text-[20px] text-[16px] text-black font-normal">
                            Tabla
                          </span>
                          <span>
                            {isTabla ? <FaChevronUp /> : <FaChevronDown />}
                          </span>
                        </div>
                        {isTabla && (
                          <div className="pt-[16px]">
                            {tabla.map((category, index) => (
                              <div
                                key={index}
                                className="flex justify-between items-center mb-2"
                              >
                                <div className="flex items-start gap-[8px]">
                                  <div>
                                    <input
                                      type="checkbox"
                                      className="sm:w-[22px] w-[18px] sm:h-[22px] h-[18px]"
                                      checked={selected.includes(category.name)}
                                      onChange={() =>
                                        handleCheckboxChange(category.name)
                                      }
                                    />
                                  </div>
                                  <div className="sm:text-[18px] text-[14px] text-gray1 font-normal hover:text-primary">
                                    {category.name}
                                  </div>
                                </div>
                                <div className="sm:text-[18px] text-[14px] text-secondary2 font-normal hover:text-gray1">
                                  {category.count}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="p-4 border-b">
                        <div
                          className="flex justify-between items-center cursor-pointer sm:pl-[25px] pl-[18px]"
                          onClick={toggleAccordionDrums}
                        >
                          <span className="sm:text-[20px] text-[16px] text-black font-normal">
                            Drums
                          </span>
                          <span>
                            {isDrums ? <FaChevronUp /> : <FaChevronDown />}
                          </span>
                        </div>
                        {isDrums && (
                          <div className="pt-[16px]">
                            {drums.map((category, index) => (
                              <div
                                key={index}
                                className="flex justify-between items-center mb-2"
                              >
                                <div className="flex items-start gap-[8px]">
                                  <div>
                                    <input
                                      type="checkbox"
                                      className="sm:w-[22px] w-[18px] sm:h-[22px] h-[18px]"
                                      checked={selected.includes(category.name)}
                                      onChange={() =>
                                        handleCheckboxChange(category.name)
                                      }
                                    />
                                  </div>
                                  <div className="sm:text-[18px] text-[14px] text-gray1 font-normal hover:text-primary">
                                    {category.name}
                                  </div>
                                </div>
                                <div className="sm:text-[18px] text-[14px] text-secondary2 font-normal hover:text-gray1">
                                  {category.count}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div>
                {" "}
                <div
                  className="flex justify-between items-center cursor-pointer p-4 border-t border-t-[#ACACAC] border-b border-b-[#ACACAC] mt-[16px]"
                  onClick={toggleAccordionRating}
                >
                  <span className="sm:text-[28px] text-[20px] font-normal text-black">
                    Rating
                  </span>

                  <span>{isRating ? <FaChevronUp /> : <FaChevronDown />}</span>
                </div>
                {isRating && (
                  <>
                    <div className="p-[16px_16px_0px_16px]">
                      {rating.map((category, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center mt-2"
                        >
                          <div className="flex items-start gap-[8px]">
                            <div>
                              <input
                                type="checkbox"
                                className="sm:w-[22px] w-[18px] sm:h-[22px] h-[18px]"
                                checked={selected.includes(category.name)}
                                onChange={() =>
                                  handleCheckboxChange(category.name)
                                }
                              />
                            </div>
                            <div>
                              <AiFillStar className="sm:w-[24px] w-[18px] sm:h-[24px] h-[18px] text-primary" />
                              {/* {category.star} */}
                            </div>
                            <div className="sm:text-[18px] text-[14px] text-gray1 font-normal hover:text-primary">
                              {category.name}
                            </div>
                          </div>
                          <div className="sm:text-[18px] text-[14px] text-secondary2 font-normal hover:text-gray1">
                            {category.count}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
              <div>
                <div
                  className="flex justify-between items-center cursor-pointer p-4 border-t border-t-[#ACACAC] border-b border-b-[#ACACAC] mt-[16px]"
                  onClick={toggleAccordionCourse}
                >
                  <span className="sm:text-[28px] text-[20px] font-normal text-black">
                    Course level
                  </span>

                  <span>{isCourse ? <FaChevronUp /> : <FaChevronDown />}</span>
                </div>
                {isCourse && (
                  <>
                    <div className="p-[16px_16px_0px_16px]">
                      {course.map((category, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center mt-2"
                        >
                          <div className="flex items-start gap-[8px]">
                            <div>
                              <input
                                type="checkbox"
                                className="sm:w-[22px] w-[18px] sm:h-[22px] h-[18px]"
                                checked={selected.includes(category.name)}
                                onChange={() =>
                                  handleCheckboxChange(category.name)
                                }
                              />
                            </div>
                            <div className="sm:text-[18px] text-[14px] text-gray1 font-normal hover:text-primary">
                              {category.name}
                            </div>
                          </div>
                          <div className="sm:text-[18px] text-[14px] text-secondary2 font-normal hover:text-gray1">
                            {category.count}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>

              <div>
                <div
                  className="flex justify-between items-center cursor-pointer p-4 border-t border-t-[#ACACAC] border-b border-b-[#ACACAC] mt-[16px]"
                  onClick={toggleAccordionPrice}
                >
                  <span className="sm:text-[28px] text-[20px] font-normal text-black">
                    Price
                  </span>

                  <span>{isPrice ? <FaChevronUp /> : <FaChevronDown />}</span>
                </div>
                {isPrice && (
                  <>
                    <div className="p-[16px_16px_0px_16px]">
                      <div>
                        {" "}
                        <div className="flex justify-center items-center w-full h-10">
                          <input
                            type="range"
                            min="0"
                            max="100"
                            value={value}
                            onChange={handleChange}
                            className="w-full h-3 bg-blue-200 rounded-full appearance-none cursor-pointer accent-blue-600"
                            style={{
                              background: `linear-gradient(to right, #001a5b ${value}%, #e5f0ff ${value}%)`,
                            }}
                          />
                          <div className="ml-4 text-blue-900 font-semibold">
                            {value}
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center justify-between gap-3">
                        <div className="bg-white border border-[#ACACAC] w-[176px] h-[60px] p-[18px_13px] flex items-center justify-between">
                          <div className="flex items-center gap-1">
                            <BsCurrencyDollar className="w-[24px] h-[24px] text-black" />
                            <h3 className="text-[20px] text-gray3 font-normal">
                              min:
                            </h3>
                          </div>
                          <div></div>
                        </div>
                        <div className="bg-white border border-[#ACACAC] w-[176px] h-[60px] p-[18px_13px] flex items-center justify-between">
                          <div className="flex items-center gap-1">
                            <BsCurrencyDollar className="w-[24px] h-[24px] text-black" />
                            <h3 className="text-[20px] text-gray3 font-normal">
                              min:
                            </h3>
                          </div>
                          <div></div>
                        </div>
                      </div>
                      {price.map((category, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center mt-2"
                        >
                          <div className="flex items-start gap-[8px]">
                            <div>
                              <input
                                type="checkbox"
                                className="sm:w-[22px] w-[18px] sm:h-[22px] h-[18px]"
                                checked={selected.includes(category.name)}
                                onChange={() =>
                                  handleCheckboxChange(category.name)
                                }
                              />
                            </div>
                            <div className="sm:text-[18px] text-[14px] text-gray1 font-normal hover:text-primary">
                              {category.name}
                            </div>
                          </div>
                          <div className="sm:text-[18px] text-[14px] text-secondary2 font-normal hover:text-gray1">
                            {category.count}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>

              <div className="mt-[16px] mb-[42px]">
                <div
                  className="flex justify-between items-center cursor-pointer p-4 border-t border-t-[#ACACAC] border-b border-b-[#ACACAC] "
                  onClick={toggleAccordionDuration}
                >
                  <span className="sm:text-[28px] text-[20px] font-normal text-black">
                    Duration
                  </span>

                  <span>
                    {isDuration ? <FaChevronUp /> : <FaChevronDown />}
                  </span>
                </div>
                {isDuration && (
                  <>
                    <div className="p-[16px_16px_0px_16px]">
                      {duration.map((category, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center mt-2"
                        >
                          <div className="flex items-start gap-[8px]">
                            <div>
                              <input
                                type="checkbox"
                                className="sm:w-[22px] w-[18px] sm:h-[22px] h-[18px]"
                                checked={selected.includes(category.name)}
                                onChange={() =>
                                  handleCheckboxChange(category.name)
                                }
                              />
                            </div>
                            <div className="sm:text-[18px] text-[14px] text-gray1 font-normal hover:text-primary">
                              {category.name}
                            </div>
                          </div>
                          <div className="sm:text-[18px] text-[14px] text-secondary2 font-normal hover:text-gray1">
                            {category.count}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="md:py-[47px] py-[16px] grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 xl:gap-[70px] lg:gap-[20px] gap-[47px]">
          {userData.map((user) => (
            <div key={user.id} className=" bg-white border border-[#ACACAC]">
              <Link href="#">
                <img className="w-full" src={user.image} alt={user.name} />
              </Link>
              <div className="p-[18px_21px] text-center border-b border-b-[#ACACAC]">
                <h1 className="sm:text-[24px] text-[20px] text-black font-medium mb-[15px]">
                  {user.name}
                </h1>
                <p className="sm:text-[20px] text-18px] text-secondary2 font-normal mb-[15px] line-clamp-1">
                  Specialty: {user.specialty}
                </p>
                <p className="sm:text-[20px] text-18px] text-secondary2 font-normal mb-0">
                  {user.level}
                </p>
              </div>
              <div className="p-[13px_21px_21px_21px] text-center">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-[6px]">
                    <AiFillStar className="text-primary w-[24px] h-[24px]" />
                    <h1 className="text-gray1 sm:text-[14px] text-[12px] font-normal mb-0">
                      {user.rating}
                    </h1>
                  </div>
                  <h2 className="sm:text-[14px] text-[12px] font-normal text-black">
                    {user.students.toLocaleString()} &nbsp;
                    <span className="text-[14px] font-normal text-secondary2">
                      students
                    </span>
                  </h2>
                </div>
                <button className="sm:mt-[30px] mt-[22px] text-primary sm:text-[18px] text-[16px] w-full h-[43px] bg-lightblue rounded">
                  Send message
                </button>
                <button className="mt-[13px] w-full h-[43px] sm:text-[18px] text-[16px] bg-primary text-white rounded">
                  Join Now for ${user.price}/h
                </button>
              </div>
            </div>
          ))}
        </div>
        <div>
          <nav
            aria-label="Pagination"
            className="flex items-center sm:justify-end justify-center gap-3 my-[23px]"
          >
            <Link href="#" className="">
              <BsFillArrowLeftCircleFill className="sm:w-[47px] w-[34px] sm:h-[47px] h-[34px] text-primary" />
            </Link>
            <Link
              href="#"
              className="px-4 py-2 rounded-full sm:w-[47px] w-[34px] sm:h-[47px] h-[34px] hover:bg-primary flex items-center justify-center hover:text-white text-black sm:text-[24px] text-[18px]"
            >
              1
            </Link>
            <Link
              href="#"
              className="px-4 py-2 rounded-full sm:w-[47px] w-[34px] sm:h-[47px] h-[34px] hover:bg-primary flex items-center justify-center hover:text-white text-black sm:text-[24px] text-[18px]"
            >
              2
            </Link>
            <Link
              href="#"
              className="px-4 py-2 rounded-full sm:w-[47px] w-[34px] sm:h-[47px] h-[34px] hover:bg-primary flex items-center justify-center hover:text-white text-black sm:text-[24px] text-[18px]"
            >
              3
            </Link>
            <Link href="#" className=" ">
              <BsFillArrowRightCircleFill className="sm:w-[47px] w-[34px] sm:h-[47px] h-[34px] text-primary" />
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default CourseList;
