import React, { useState } from "react";
import LOGO from "../../assets/Images/Logo.png";
import { BiSearch } from "react-icons/bi";
import { IoNotificationsOutline } from "react-icons/io5";
import { IoIosHeartEmpty, IoIosArrowDown } from "react-icons/io";
import { BsCart2 } from "react-icons/bs";
import { Link } from "react-router-dom";
import { CgMenu } from "react-icons/cg";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";

const countries = [
  { id: 1, name: "USD1" },
  { id: 2, name: "USD2" },
  { id: 3, name: "USD3" },
];

const language = [
  { id: 1, name: "English" }, 
  { id: 2, name: "Gujarati" },
  { id: 3, name: "Hindi" },
];
function AuthHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [selectedlanguages, setSelectedLanguages] = useState(language[0]);

  const handleSelect = (country) => {
    setSelectedCountry(country);
  };

  const handleSelected = (lan) => {
    setSelectedLanguages(lan);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div>
        <header>
          <div className="bg-primary sm:p-[16px_40px] p-[16px] flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="lg:hidden block">
                <CgMenu
                  className="w-[25px] h-[25px] text-white "
                  onClick={toggleDropdown}
                />
              </div>
              <ul class="lg:flex items-center gap-[25px] hidden">
                <li class=" border-gray-300">
                  <Link
                    to="/"
                    class=" hover:text-white text-secondary2 block font-semibold text-[15px]"
                  >
                    Home
                  </Link>
                </li>
                <li class=" border-gray-300">
                  <Link
                    to="/course"
                    class=" hover:text-white text-secondary2 block font-semibold text-[15px]"
                  >
                    Course
                  </Link>
                </li>
                <li class=" border-gray-300">
                  <Link
                    to=""
                    class=" hover:text-white text-secondary2 block font-semibold text-[15px]"
                  >
                    Menu
                  </Link>
                </li>
                <li class=" border-gray-300">
                  <Link
                    to=""
                    class=" hover:text-white text-secondary2 block font-semibold text-[15px]"
                  >
                    Messages
                  </Link>
                </li>
                <li class=" border-gray-300">
                  <Link
                    to=""
                    class=" hover:text-white text-secondary2 block font-semibold text-[15px]"
                  >
                    Payment Portal
                  </Link>
                </li>
                <li class=" border-gray-300">
                  <Link
                    to=""
                    class=" hover:text-white text-secondary2 block font-semibold text-[15px]"
                  >
                    Calendar
                  </Link>
                </li>
              </ul>
            </div>

            <div className="flex items-center gap-[7px]">
              <div>
                <Listbox
                  className=""
                  value={selectedCountry}
                  onChange={(e) => {
                    setSelectedCountry(e);
                  }}
                >
                  <div className="">
                    <ListboxButton className="relative flex items-center gap-[8px] w-[62px] h-[27px] border-0 placeholder:text-[14px] text-secondary2 placeholder:text-secondary2 focus:outline-none bg-transparent">
                      <div
                        className="flex items-center"
                        aria-expanded="true"
                        aria-haspopup="true"
                      >
                        <span>{selectedCountry.name}</span>
                      </div>
                      <div className="pointer-events-none inset-y-0 right-[10px]">
                        <IoIosArrowDown />
                      </div>
                    </ListboxButton>

                    <ListboxOptions className="absolute z-10 mt-1 w-[70px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {countries.map((country) => (
                        <ListboxOption
                          key={country.id}
                          value={country}
                          onClick={() => handleSelect(country)}
                          role="menuitem"
                          tabIndex="-1"
                          className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-primary data-[focus]:text-white"
                        >
                          {country.name}
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </div>
                </Listbox>
              </div>
              <div>
                <Listbox
                  className=""
                  value={selectedlanguages}
                  onChange={(e) => {
                    setSelectedLanguages(e);
                  }}
                >
                  <div className="">
                    <ListboxButton className="relative flex items-center gap-[8px] w-[80px] h-[27px] border-0 placeholder:text-[14px] text-secondary2 placeholder:text-secondary2 focus:outline-none bg-transparent">
                      <div
                        className="flex items-center"
                        aria-expanded="true"
                        aria-haspopup="true"
                      >
                        <span>{selectedlanguages.name}</span>
                      </div>
                      <div className="pointer-events-none inset-y-0 right-[10px]">
                        <IoIosArrowDown />
                      </div>
                    </ListboxButton>

                    <ListboxOptions className="absolute z-10 mt-1 w-[80px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {language.map((lan) => (
                        <ListboxOption
                          key={lan.id}
                          value={lan}
                          onClick={() => handleSelected(lan)}
                          role="menuitem"
                          tabIndex="-1"
                          className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-primary data-[focus]:text-white"
                        >
                          {lan.name}
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </div>
                </Listbox>
              </div>
            </div>
          </div>
          <div className="w-full sm:p-[16px_40px] p-[16px] border-b border-b-[#ACACAC]">
            <div className="w-full flex items-center justify-between  gap-3">
              <div className="flex items-center lg:gap-[70px] sm:gap-[50px] gap-[16px]">
                <img
                  src={LOGO}
                  alt=""
                  className="md:w-[92px] w-[45px] md:h-[103px] h-[51px]"
                />
                <div className="relative lg:flex hidden items-center border border-[#ACACAC] p-[13px_15px] h-[50px] rounded w-[340px]">
                  <BiSearch className="absolute w-[23px] h-[24px]" />
                  <input
                    className="bg-transparent pl-[30px] text-[16px] font-medium text-black placeholder:text-secondary2 transition duration-300 ease focus:outline-none focus:border-slate-400"
                    placeholder="What do you want learn..."
                  />
                </div>
              </div>
              <div className="flex items-center sm:gap-[33px] gap-[7px]">
                <div className="flex items-center gap-[20px]">
                  <div className="lg:flex hidden items-center gap-[20px]">
                    <IoNotificationsOutline className="w-[28px] h-[28px] text-nevyblue" />
                    <IoIosHeartEmpty className="w-[28px] h-[28px] text-nevyblue" />
                    <BsCart2 className="w-[28px] h-[28px] text-nevyblue" />
                  </div>
                  <Link
                    to="/signup"
                    className="text-primary flex items-center justify-center sm:text-[18px] text-[12px] font-medium p-[12px] sm:w-[211px] w-[112px] sm:h-[50px] h-[35px] bg-lightblue rounded"
                  >
                    Create Account
                  </Link>
                </div>
                <Link
                  to="/login"
                  className="text-white flex items-center justify-center sm:text-[18px] text-[12px] font-medium p-[12px] sm:w-[120px] w-[72px] sm:h-[50px] h-[35px] bg-primary rounded"
                >
                  Sign In
                </Link>
              </div>
            </div>
            <div className="flex justify-center items-center ">
              <div className="relative lg:hidden block items-center border border-[#ACACAC] p-[13px_15px] h-[50px] rounded w-[340px] mt-[22px]">
                <BiSearch className="absolute w-[23px] h-[24px]" />
                <input
                  className="bg-transparent pl-[30px] sm:text-[16px] text-[14px] font-medium text-black placeholder:text-secondary2 transition duration-300 ease focus:outline-none focus:border-slate-400"
                  placeholder="What do you want learn..."
                />
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
}

export default AuthHeader;
