import React, { useState } from "react";
import LOGO from "../../assets/Images/Logo.png";
import LOGOFOOTER from "../../assets/Images/Logofooter.png";
import { Link } from "react-router-dom";
import ABOUTIMG from "../../assets/Images/aboutImage.jpg";
import DANCEIMG from "../../assets/Images/Danceimg.png";
import MUSICIMG from "../../assets/Images/MusicImg.png";
import CALLIMG from "../../assets/Images/Calling.svg";
import MESSAGE from "../../assets/Images/Message.svg";
import LOCATION from "../../assets/Images/Location.svg";
import ARTSIMG from "../../assets/Images/ArtsImg.png";
import REACTICON from "../../assets/Images/reacticon.png";
import LIGHTICON from "../../assets/Images/lighticon.png";
import GLOBAL from "../../assets/Images/global.png";
import MONITERICON from "../../assets/Images/monitericon.png";
import PENCIL from "../../assets/Images/pencil.png";
import { FaAngleDoubleRight, FaStar } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { BsCameraVideo } from "react-icons/bs";
import { TbClockHour3 } from "react-icons/tb";
import { GrDownload } from "react-icons/gr";
import { BiSearch } from "react-icons/bi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getUserRole, isAuthenticated } from "../router/ProtectedRoute";

function LandingPage() {
  const [isOpen, setIsOpen] = useState(false);
  const role = getUserRole();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div>
        <div className="xl:px-[80px] md:px-[40px] px-[20px]">
          <header>
            <nav className="py-2 md:py-4">
              <div className="flex flex-wrap justify-between items-center mx-auto">
                <button className="flex items-center">
                  <img
                    src={LOGO}
                    alt=""
                    className="w-full xl:max-w-full md:max-w-[110px] max-w-[70px]"
                  />
                </button>
                <div className="flex items-center gap-3">
                  <div className="hidden justify-between !items-center w-full lg:flex lg:w-auto headerlink gap-[28px]">
                    <ul className="flex !flex-col md:!flex-row  xl:gap-[45px] gap-[20px] font-medium  lg:space-x-8 lg:mt-0 whitespace-nowrap">
                      <li>
                        <Link
                          to="/"
                          className="p-2 lg:px-4  text-gray2 hover:text-primary font-medium text-[18px]"
                        >
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="p-2 lg:px-4  text-gray2 hover:text-primary font-medium text-[18px] transition-colors duration-300"
                        >
                          About us
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/course"
                          className="p-2 lg:px-4  text-gray2 hover:text-primary font-medium text-[18px] transition-colors duration-300"
                        >
                          Courses
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="p-2 lg:px-4 text-gray2 hover:text-primary font-medium text-[18px] transition-colors duration-300"
                        >
                          Contact us
                        </Link>
                      </li>
                    </ul>
                    <div className="flex gap-[22px]">
                      <div className="w-full sm:max-w-[242px]">
                        <div className="relative flex items-center bg-white1">
                          <BiSearch className="absolute w-5 h-5 top-2.5 left-2.5 text-slate-600" />

                          <input
                            className="w-full placeholder:text-lightgray text-black text-[16px] font-medium bg-white1 rounded-md pl-10 pr-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 shadow-sm focus:shadow bg-transparent"
                            placeholder="Search for teachers"
                          />
                        </div>
                      </div>
                      {!isAuthenticated() ? (
                        <div className="flex gap-[8px]">
                          <Link
                            to="/login"
                            className="p-2 lg:px-4  text-center border-[2px] border-primary text-primary text-[18px] font-medium rounded-full flex justify-center items-center w-[125px] h-[42px] hover:bg-primary hover:text-white"
                          >
                            Log in
                          </Link>
                          <Link
                            to="/signup"
                            className="p-2 lg:px-4  text-center border-[2px] border-primary text-primary text-[18px] font-medium rounded-full flex justify-center items-center w-[125px] h-[42px] hover:bg-primary hover:text-white"
                          >
                            Sign up
                          </Link>
                        </div>
                      ) : (
                        <div className="flex gap-[8px]">
                          <Link
                            to={`${role}/profile`}
                            className="p-2 lg:px-4  text-center border-[2px] border-primary text-primary text-[18px] font-medium rounded-full flex justify-center items-center w-[125px] h-[42px] hover:bg-primary hover:text-white"
                          >
                            Dashboard
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-full max-w-[242px] lg:hidden">
                    <div className="relative flex items-center bg-white1">
                      <BiSearch className="absolute w-5 h-5 top-2.5 left-2.5 text-slate-600" />

                      <input
                        className="w-full placeholder:text-lightgray text-black text-[16px] font-medium bg-white1 rounded-md pl-10 pr-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 shadow-sm focus:shadow bg-transparent"
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-3">
                    <button
                      className="lg:hidden hover:bg-gray-100"
                      onClick={toggleDropdown}
                    >
                      <GiHamburgerMenu className="w-[30px] h-[30px]" />
                    </button>
                  </div>

                  {isOpen && (
                    <div className="absolute right-4 md:top-[80px] lg:top-[40px] sm:top-[50px] top-28 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg">
                      <ul className="py-2">
                        <li className="px-4 py-2 hover:bg-primary hover:text-white">
                          <Link to="/"> Home</Link>
                        </li>
                        <li className="px-4 py-2 hover:bg-primary hover:text-white">
                          <Link to="#">About us</Link>
                        </li>
                        <li className="px-4 py-2 hover:bg-primary hover:text-white">
                          <Link to="/course"> Courses</Link>
                        </li>
                        <li className="px-4 py-2 hover:bg-primary hover:text-white">
                          <Link href="">Contact us</Link>
                        </li>
                        {!isAuthenticated() ? (
                          <li className="px-4 py-2 hover:bg-primary hover:text-white">
                            <div className="flex flex-col gap-[8px]">
                              <Link
                                to="/login"
                                className="p-2 lg:px-4  text-center border-[2px] border-primary text-primary text-[18px] font-medium rounded-full flex justify-center items-center w-[125px] h-[42px] hover:bg-primary hover:text-white"
                              >
                                Log in
                              </Link>
                              <Link
                                to="/signup"
                                className="p-2 lg:px-4  text-center border-[2px] border-primary text-primary text-[18px] font-medium rounded-full flex justify-center items-center w-[125px] h-[42px] hover:bg-primary hover:text-white"
                              >
                                Sign in
                              </Link>
                            </div>
                          </li>
                        ) : (
                          <li className="px-4 py-2 hover:bg-primary hover:text-white">
                            <div className="flex flex-col gap-[8px]">
                              <Link
                                to="/login"
                                className="p-2 lg:px-4  text-center border-[2px] border-primary text-primary text-[18px] font-medium rounded-full flex justify-center items-center w-[125px] h-[42px] hover:bg-primary hover:text-white"
                              >
                                Dashboard
                              </Link>
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </nav>
          </header>
        </div>
        <div className="relative">
          <img src={REACTICON} alt="" className="absolute left-[18px]" />
          <img
            src={GLOBAL}
            alt=""
            className="absolute left-[25px] bottom-[50px]"
          />
          <img src={PENCIL} alt="" className="absolute left-0 bottom-[200px]" />

          <div className="xl:px-[80px] md:px-[40px] px-[20px]">
            <div className="lg:gap-[10px] xl:gap-16 items-center mx-auto lg:grid lg:grid-cols-2 ">
              <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400 relative">
                <img src={LIGHTICON} alt="" className="absolute left-[0]" />
                <img src={MONITERICON} alt="" className="absolute right-0" />

                <h2 className="text-black1 font-black xl:text-[46px] md:text-[40px] md:pt-10 pt-[30px] sm:text-[35px] text-[25px] md:leading-[50px] sm:leading-[38px] leading-[30px] md:whitespace-nowrap">
                  Learn Anything, Anywhere
                </h2>
                <p className="md:mt-[16px] !mt-[25px] text-lightgray lg:max-w-[654px] max-w-[654px] w-full text-[14px] sm:text-[16px] font-normal">
                  “At Siqsha , we’re on a thrilling mission to revolutionize
                  learning and skill-sharing !
                </p>
                <p className="mt-[5px] text-lightgray lg:max-w-[654px] max-w-[654px] w-full text-[14px] sm:text-[16px] font-normal">
                  Who are we, you ask? We’re a passionate team dedicated to
                  empowering learners and instructors alike with the best
                  technology available. Our innovative platform connects
                  individuals from all corners of the globe, making it possible
                  to learn or enhance skills in virtually any area—be it
                  mastering a new language, diving into coding, exploring
                  creative arts, or even engaging in physical therapy, personal
                  training, and yoga.
                </p>
                <p className="mt-[5px] text-lightgray lg:max-w-[654px] max-w-[654px] w-full text-[14px] sm:text-[16px] font-normal">
                  Siqsha goes beyond traditional education; we provide an
                  engaging and accessible environment that fosters growth,
                  curiosity, and connection. Join us on this exciting journey as
                  we break down barriers and inspire everyone to expand their
                  horizons by learning from each other!”
                </p>
                <Link
                  href="#"
                  className="max-w-[250px] w-full max-h-[66px] h-full flex justify-between items-center p-[18px_40px] gap-[10px] bg-primary text-white rounded-lg mt-[16px]"
                >
                  <div className="text-[20px] font-bold">Learn More</div>
                  <div>
                    <FaAngleDoubleRight className="w-[22px] h-[22px]" />
                  </div>
                </Link>
              </div>
              <div className="flex justify-center">
                <img src={ABOUTIMG} alt="" className="max-w-[896px] w-full" />
              </div>
            </div>
          </div>
        </div>
        <div className="xl:px-[80px] md:px-[40px] px-[20px]">
          {" "}
          <section className="px-3">
            <h2 className="font-bold md:text-[46px] lg:text-[37px] text-[30px] text-black1 text-center  md:pb-[89px] lg:pb-[70px] pb-[30px]">
              Trading courses
            </h2>
            <div className="slider-container">
              <Slider {...settings}>
                <div>
                  <div className="relative bg-white">
                    <div className=" rounded shadow-[0px_4px_25px_#00000019] p-[13px]">
                      <img
                        className="w-full"
                        src={DANCEIMG}
                        alt="Sunset in the mountains"
                      />
                      <div className="flex justify-between items-center pt-[14px] gap-2 flex-wrap">
                        <div className="text-gray3 text-[16px] font-medium ">
                          Dance Course
                        </div>
                        <div className="flex justify-between items-center gap-1">
                          <FaStar className="text-yellow" />
                          <FaStar className="text-yellow" />
                          <FaStar className="text-yellow" />
                          <FaStar className="text-yellow" />
                          <FaStar className="text-lightyellow" />
                        </div>
                      </div>
                      <p className="mb-0 md:text-[20px] text-[17px] font-medium text-black pt-2">
                        Dance Foundations for Beginners
                      </p>
                      <h1 className="text-[20px] font-semibold text-primary">
                        $99
                      </h1>
                      <div className="flex justify-between items-center py-[25px] gap-2 flex-wrap">
                        <div className="flex justify-between items-center gap-[6px]">
                          <div>
                            <TbClockHour3 />
                          </div>
                          <h1 className="text-[16px] font-medium text-gray3">
                            22hr 30min
                          </h1>
                        </div>
                        <div className="flex justify-between items-center gap-[6px]">
                          <div>
                            <BsCameraVideo />
                          </div>
                          <h1 className="text-[16px] font-medium text-gray3">
                            34 Courses
                          </h1>
                        </div>
                        <div className="flex justify-between items-center gap-[6px]">
                          <div>
                            <GrDownload />
                          </div>
                          <h1 className="text-[16px] font-medium text-gray3">
                            250 Sales
                          </h1>
                        </div>
                      </div>
                      <div className="bg-primary rounded-full md:max-w-[206px] lg:max-w-[190px] max-w-[150px] w-full lg:max-h-[55px] max-h-[40px] h-full flex justify-center items-center mx-auto absolute bottom-[-25px] left-0 right-0">
                        <button className="text-white md:text-[20px] text-[16px] font-medium">
                          Join Course
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="relative bg-white">
                    <div className="rounded shadow-[0px_4px_25px_#00000019] p-[13px]">
                      <img
                        className="w-full"
                        src={MUSICIMG}
                        alt="Sunset in the mountains"
                      />
                      <div className="flex justify-between items-center pt-[14px] gap-2 flex-wrap">
                        <div className="text-gray3 text-[16px] font-medium ">
                          Dance Course
                        </div>
                        <div className="flex justify-between items-center gap-1">
                          <FaStar className="text-yellow" />
                          <FaStar className="text-yellow" />
                          <FaStar className="text-yellow" />
                          <FaStar className="text-yellow" />
                          <FaStar className="text-lightyellow" />
                        </div>
                      </div>
                      <p className="mb-0 md:text-[20px] text-[17px] font-medium text-black pt-2">
                        Dance Foundations for Beginners
                      </p>
                      <h1 className="text-[20px] font-semibold text-primary">
                        $99
                      </h1>
                      <div className="flex justify-between items-center py-[25px] gap-2 flex-wrap">
                        <div className="flex justify-between items-center gap-[6px]">
                          <div>
                            <TbClockHour3 />
                          </div>
                          <h1 className="text-[16px] font-medium text-gray3">
                            22hr 30min
                          </h1>
                        </div>
                        <div className="flex justify-between items-center gap-[6px]">
                          <div>
                            <BsCameraVideo />
                          </div>
                          <h1 className="text-[16px] font-medium text-gray3">
                            34 Courses
                          </h1>
                        </div>
                        <div className="flex justify-between items-center gap-[6px]">
                          <div>
                            <GrDownload />
                          </div>
                          <h1 className="text-[16px] font-medium text-gray3">
                            250 Sales
                          </h1>
                        </div>
                      </div>
                      <div className="bg-primary rounded-full md:max-w-[206px] lg:max-w-[190px] max-w-[150px] w-full lg:max-h-[55px] max-h-[40px] h-full flex justify-center items-center mx-auto absolute bottom-[-25px] left-0 right-0">
                        <button className="text-white md:text-[20px] text-[16px] font-medium">
                          Join Course
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="relative bg-white">
                    <div className="rounded shadow-[0px_4px_25px_#00000019] p-[13px]">
                      <img
                        className="w-full"
                        src={ARTSIMG}
                        alt="Sunset in the mountains"
                      />
                      <div className="flex justify-between items-center pt-[14px] gap-2 flex-wrap">
                        <div className="text-gray3 text-[16px] font-medium ">
                          Dance Course
                        </div>
                        <div className="flex justify-between items-center gap-1">
                          <FaStar className="text-yellow" />
                          <FaStar className="text-yellow" />
                          <FaStar className="text-yellow" />
                          <FaStar className="text-yellow" />
                          <FaStar className="text-lightyellow" />
                        </div>
                      </div>
                      <p className="mb-0 md:text-[20px] text-[17px] font-medium text-black pt-2">
                        Dance Foundations for Beginners
                      </p>
                      <h1 className="text-[20px] font-semibold text-primary">
                        $99
                      </h1>
                      <div className="flex justify-between items-center py-[25px] gap-2 flex-wrap">
                        <div className="flex justify-between items-center gap-[6px]">
                          <div>
                            <TbClockHour3 />
                          </div>
                          <h1 className="text-[16px] font-medium text-gray3">
                            22hr 30min
                          </h1>
                        </div>
                        <div className="flex justify-between items-center gap-[6px]">
                          <div>
                            <BsCameraVideo />
                          </div>
                          <h1 className="text-[16px] font-medium text-gray3">
                            34 Courses
                          </h1>
                        </div>
                        <div className="flex justify-between items-center gap-[6px]">
                          <div>
                            <GrDownload />
                          </div>
                          <h1 className="text-[16px] font-medium text-gray3">
                            250 Sales
                          </h1>
                        </div>
                      </div>
                      <div className="bg-primary rounded-full md:max-w-[206px] lg:max-w-[190px] max-w-[150px] w-full lg:max-h-[55px] max-h-[40px] h-full flex justify-center items-center mx-auto absolute bottom-[-25px] left-0 right-0">
                        <button className="text-white md:text-[20px] text-[16px] font-medium">
                          Join Course
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </section>
        </div>
        <footer className="mt-[122px]">
          <div className="bg-cover w-full footerbg ">
            <div className="p-3">
              <div className="lg:p-[150px_80px_29px_80px] pt-[29px]">
                <div className="flex flex-wrap gap-3 justify-between px-6">
                  <div>
                    <img src={LOGOFOOTER} alt="" />
                    <p className="mb-0 text-[16px] mt-[14px] font-normal text-white max-w-[272px] w-full">
                      Learn  Anything, Anywhere.
                    </p>
                  </div>
                  <div className="">
                    <h1 className="text-[24px] font-medium text-white mb-[28px]">
                      Company
                    </h1>
                    <ul>
                      <a href="#">
                        <li className="text-[16px] font-normal text-white mb-[20px]">
                          About Us
                        </li>
                      </a>
                      <a href="#">
                        {" "}
                        <li className="text-[16px] font-normal text-white mb-[20px]">
                          Popular Course
                        </li>
                      </a>
                      <a href="#">
                        {" "}
                        <li className="text-[16px] font-normal text-white">
                          Service
                        </li>
                      </a>
                    </ul>
                  </div>
                  <div className="">
                    <h1 className="text-[24px] font-medium text-white mb-[28px]">
                      Support
                    </h1>
                    <ul>
                      <a href="#">
                        {" "}
                        <li className="text-[16px] font-normal text-white mb-[20px]">
                          FAQ
                        </li>
                      </a>
                      <a href="#">
                        <li className="text-[16px] font-normal text-white">
                          Privacy
                        </li>
                      </a>
                    </ul>
                  </div>
                  <div className="">
                    <h1 className="text-[24px] font-medium text-white mb-[28px]">
                      Contact Info
                    </h1>
                    <ul>
                      <li className="text-[16px] font-normal text-white mb-[20px] flex gap-[13px]">
                        <span>
                          <img src={CALLIMG} alt="" />
                        </span>
                        +0913-705-3875
                      </li>
                      <li className="text-[16px] font-normal text-white mb-[20px] flex gap-[13px]">
                        <span>
                          <img src={MESSAGE} alt="" />
                        </span>
                        ElizabethJ@jourrapide.com
                      </li>
                      <li className="text-[16px] font-normal text-white mb-[20px] flex gap-[13px]">
                        <span>
                          <img src={LOCATION} alt="" />
                        </span>
                        4808 Skinner Hollow Road Days Creek, OR 97429
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className=" py-[22px] border-t border-[#D9D9D9]">
              <p className="mb-0 text-[16px] font-normal text-white text-center">
                © Copyright 2020 Lorem Inc. All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default LandingPage;
