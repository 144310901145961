import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "../landingPage/LandingPage";
import ProtectedRoute from "./ProtectedRoute";
import NotFound from "../notFound/NotFound";
import Login from "../auth/login/Login";
import Signup from "../auth/signup/Signup";
import ForgotPassword from "../auth/forgotPassword/ForgotPassword";
import ResetPassword from "../auth/resetPassword/ResetPassword";
import ReactiveAccount from "../auth/reactiveAccount/ReactiveAccount";
import StudentProfile from "../students/studentProfile/StudentProfile";
import TeacherProfile from "../teachers/TeacherProfile/TeacherProfile";
import AdminDashboard from "../admin/pages/AdminProfile/AdminDashboard";
import UserSettings from "../admin/pages/AdminProfile/UserSettings";
import StudentDetails from "../admin/pages/AdminProfile/StudentDetails";
import Profile from "../admin/pages/AdminProfile/Profile";
import TeacherDetails from "../admin/pages/AdminProfile/TeacherDetails";
import ApprovalDetails from "../admin/pages/AdminProfile/ApprovalDetails";
import NewCourse from "../course/newCourse/NewCourse";
import MySchedule from "../schedule/mySchedule/Myschedule";
import CourseList from "../course/courseList/CourseList";
import VerifyOtp from "../auth/verifyOtp/VerifyOtp";

function Router() {
  return (
    <div>
      <Routes>
        {/* ================================ AUTHENTICATION ROUTES =================================== */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/reactive-account" element={<ReactiveAccount />} />
        <Route path="/verify-otp" element={<VerifyOtp />} />
        <Route path="/*" element={<NotFound />} />

        {/* ================================== TEACHER ROUTES ====================================== */}

        <Route
          path="/teacher/profile"
          element={<ProtectedRoute element={<TeacherProfile />} />}
        />

        {/* ================================== STUDENT ROUTES ====================================== */}
        <Route
          path="student/profile"
          element={<ProtectedRoute element={<StudentProfile />} />}
        />

        {/* ================================== COURSE ROUTES ====================================== */}

        <Route
          path="/course"
          element={<ProtectedRoute element={<CourseList />} />}
        />
        <Route
          path="/schedule"
          element={<ProtectedRoute element={<MySchedule />} />}
        />
        <Route
          path="/create-course"
          element={<ProtectedRoute element={<NewCourse />} />}
        />

        {/* ================================== ADMIN ROUTES ====================================== */}
        <Route
          path="/admin/dashboard"
          element={<ProtectedRoute element={<AdminDashboard />} />}
        />
        <Route
          path="/admin/user-settings"
          element={<ProtectedRoute element={<UserSettings />} />}
        />
        <Route
          path="/admin/students"
          element={<ProtectedRoute element={<StudentDetails />} />}
        />
        <Route
          path="/admin/profile/:id"
          element={<ProtectedRoute element={<Profile />} />}
        />
        <Route
          path="/admin/teachers"
          element={<ProtectedRoute element={<TeacherDetails />} />}
        />
        <Route
          path="/admin/approval"
          element={<ProtectedRoute element={<ApprovalDetails />} />}
        />
      </Routes>
    </div>
  );
}

export default Router;
