import React, { useEffect, useRef, useState } from "react";
import { IoMdTime } from "react-icons/io";
import { ErrorMessage } from "formik";

const TimePicker = ({
  label,
  value,
  onChange,
  onBlur,
  name,
  placeholder = "Select time",
  error,
  readOnly = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedHour, setSelectedHour] = useState("00");
  const [selectedMinute, setSelectedMinute] = useState("00");
  const [selectedPeriod, setSelectedPeriod] = useState("AM");

  const timePickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        timePickerRef.current &&
        !timePickerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const hours = Array.from({ length: 12 }, (_, i) =>
    String(i + 1).padStart(2, "0")
  );
  const minutes = Array.from({ length: 60 }, (_, i) =>
    String(i).padStart(2, "0")
  );

  const handleTimeChange = (hour, minute, period) => {
    const newHour = hour || selectedHour;
    const newMinute = minute || selectedMinute;
    const newPeriod = period || selectedPeriod;

    const timeString = `${newHour}:${newMinute}`;
    onChange?.(timeString);

    setSelectedHour(newHour);
    setSelectedMinute(newMinute);
    setSelectedPeriod(newPeriod);
  };

  return (
    <div ref={timePickerRef}>
      <label className="w-full text-[16px] text-black">{label}</label>
      <div className="relative inline-block w-full">
        <p
          className="block cursor-pointer w-full border-[1px] border-secondary2 text-black bg-white rounded-[4px] px-[16px] py-[10px] text-left mt-[5px] focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
          disabled={readOnly}
        >
          {value || placeholder}
          <IoMdTime className="absolute top-1/2 translate-y-[-50%] right-[18px] text-[18px]" />
        </p>

        {isOpen && (
          <div className="absolute mt-1 bg-white border rounded-md shadow-lg w-48 z-10">
            <div className="grid grid-cols-3 gap-1 p-2">
              {/* Hours */}
              <div className="flex flex-col h-48 overflow-y-auto scrollbar">
                <div className="text-sm font-medium text-gray-500 mb-1">
                  Hour
                </div>
                {hours.map((hour) => (
                  <div
                    key={hour}
                    className={`px-2 cursor-pointer py-1 text-left hover:bg-blue-100 rounded ${
                      selectedHour === hour ? "bg-blue-200" : ""
                    }`}
                    onClick={() => handleTimeChange(hour, null, null)}
                  >
                    {hour}
                  </div>
                ))}
              </div>

              {/* Minutes */}
              <div className="flex flex-col h-48 overflow-y-auto scrollbar">
                <div className="text-sm font-medium text-gray-500 mb-1">
                  Min
                </div>
                {minutes.map((minute) => (
                  <div
                    key={minute}
                    className={`px-2 cursor-pointer py-1 text-left hover:bg-blue-100 rounded ${
                      selectedMinute === minute ? "bg-blue-200" : ""
                    }`}
                    onClick={() => handleTimeChange(null, minute, null)}
                  >
                    {minute}
                  </div>
                ))}
              </div>

              <div className="flex flex-col">
                <div className="text-sm font-medium text-gray-500 mb-1">
                  Period
                </div>
                {["AM", "PM"].map((period) => (
                  <button
                    key={period}
                    className={`px-2 py-1 text-left hover:bg-blue-100 rounded ${
                      selectedPeriod === period ? "bg-blue-200" : ""
                    }`}
                    onClick={() => handleTimeChange(null, null, period)}
                  >
                    {period}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      {error && (
        <ErrorMessage
          name={name}
          component="div"
          className="text-red text-sm"
        />
      )}
    </div>
  );
};

export default TimePicker;
