import React from "react";

const Button = ({
  type = "button",
  onClick,
  disabled = false,
  text,
  className,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`h-[52px] sm:text-[20px] text-[18px] font-bold rounded-[6px] py-[6px] ${className}`}
    >
      {text}
    </button>
  );
};

export default Button;
