// React and related libraries
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Form libraries and validation
import { Form, Field, Formik, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-input-2/lib/style.css";
import "react-time-picker/dist/TimePicker.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "cropperjs/dist/cropper.css";

// Date handling library
import dayjs from "dayjs";

//image cropping library
import { Cropper } from "react-cropper";

// Styled-components
import styled from "styled-components";

// Icons
import { BiSolidCheckCircle, BiCheck } from "react-icons/bi";
import { MdOutlineCancel, MdOutlineCalendarMonth } from "react-icons/md";
import { FaUpload } from "react-icons/fa6";
import { BsCheckAll, BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { PiCircleFill } from "react-icons/pi";

// Images
import PERSONIMG from "../../../assets/Images/person.png";
import VISACARD from "../../../assets/Images/VisaCard.png";
import VISACARD1 from "../../../assets/Images/VisaCard1.png";
import VISA from "../../../assets/Images/Visa.png";
import CARD from "../../../assets/Images/card.png";
import PAYPAL from "../../../assets/Images/PayPal.png";

// Redux actions
import {
  updateStudentSkillMatrix,
  updateStudentProfile,
} from "../../../redux/studentProfileSlice";

// API services
import {
  getStudentProfile,
  setupStudentProfile,
} from "../../../services/apis/studentApis";

// Constants and models
import {
  Availability,
  Interest,
  Source,
  StudentTab,
} from "../../../constant/dataConstant";

// Components
import Sidebar from "../../../components/profile/Sidebar";
import Header from "../../../components/profile/Header";
import SelectList from "../../../components/common/SelectList";
import SkillMatrixForm from "../../../components/common/SkillMetricsForm";
import TextInput from "../../../components/common/TextInput";
import Button from "../../../components/common/Button";
import PhoneNumberInput from "../../../components/common/PhoneNumberInput";
import { useModal } from "../../../contexts/ModelContext";
import TimePicker from "../../../components/common/TimeInput";
import { ReactPhotoEditor } from "react-photo-editor";
import "react-photo-editor/dist/style.css";
import UserHeader from "../../../components/header/UserHeader";

const Tab = styled.button`
  ${({ active }) =>
    active &&
    `
    border-bottom: 3px solid #002060;
    opacity: 1;
  `}
`;
const ButtonGroup = styled.div`
  display: flex;
`;

function StudentProfile() {
  const { showModal } = useModal();
  const [active, setActive] = useState(StudentTab[0]);
  const [preview, setPreview] = useState(PERSONIMG);
  const images = [VISACARD, VISACARD1];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [completedTabs, setCompletedTabs] = useState([]);
  const dispatch = useDispatch();
  const initialValues = useSelector((state) => state.studentProfile);
  const [file, setFile] = useState(null);
  const [cropper, setCropper] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [image, setImage] = useState("");

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleAddSkill = (skill, values, setFieldValue, validateField) => {
    if (skill && !values.skillMatrix.some((s) => s.skill === skill)) {
      const newSkill = {
        category: skill,
        subcategory: "",
        skillLevel: "",
      };

      setFieldValue("skillMatrix", [...values.skillMatrix, newSkill]).then(
        () => {
          validateField("skillMatrix");
        }
      );
    }
    setFieldValue("skill", "");
  };

  const getCropData = (setFieldValue) => {
    if (cropper) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        const croppedFile = new File([blob], "cropped-image.jpg", {
          type: "image/jpeg",
          lastModified: new Date().getTime(),
        });
        const previewUrl = URL.createObjectURL(blob);
        if (setFieldValue) setFieldValue("profilePicture", croppedFile);
        setShowCropper(false);
        setImage(previewUrl);
        setPreview(previewUrl);
        setFile(croppedFile);
      }, "image/jpeg");
    }
  };

  const handleRemoveSkill = (
    skillToRemove,
    values,
    setFieldValue,
    validateField
  ) => {
    const updatedSkillMatrix = values.skillMatrix.filter(
      ({ category }) => category !== skillToRemove
    );

    setFieldValue("skillMatrix", updatedSkillMatrix).then(() => {
      validateField("skillMatrix");
    });
  };

  const handleAddInterest = (
    interest,
    values,
    setFieldValue,
    validateField
  ) => {
    if (interest && !values.areaOfInterest.includes(interest)) {
      setFieldValue("areaOfInterest", [
        ...values.areaOfInterest,
        interest,
      ]).then(() => {
        validateField("areaOfInterest");
      });
    }

    setFieldValue("interest", "");
  };

  const handleRemoveInterest = (
    interestToRemove,
    values,
    setFieldValue,
    validateField
  ) => {
    const updatedInterests = values.areaOfInterest.filter(
      (interest) => interest !== interestToRemove
    );
    setFieldValue("areaOfInterest", updatedInterests).then(() => {
      validateField("areaOfInterest");
    });
  };

  const validateTimeOrder = (start, end) => {
    if (!start || !end) return true;
    const startTime = new Date(`1970-01-01 ${start}`);
    const endTime = new Date(`1970-01-01 ${end}`);
    return startTime < endTime;
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First name is required")
      .max(50, "First name must be at most 50 characters"),
    lastName: Yup.string()
      .required("Last name is required")
      .max(50, "Last name must be at most 50 characters"),
    source: Yup.string().required("Source is required"),
    contactNumber: Yup.string()
      .required("Contact number is required")
      .matches(/^[0-9]+$/, "Contact number must be numeric")
      .min(10, "Contact number must be at least 10 digits")
      .max(15, "Contact number must be at most 15 digits"),
    areaOfInterest: Yup.array()
      .of(Yup.string().required("Interest is required"))
      .min(1, "At least one area of interest is required"),
    availability: Yup.string().required("Availability is required"),
    startTime: Yup.string()
      .required("Start time is required")
      .test(
        "is-before-end-time",
        "Start time must be before end time",
        function (value) {
          const { endTime } = this.parent;
          return validateTimeOrder(value, endTime);
        }
      ),
    endTime: Yup.string()
      .required("End time is required")
      .test(
        "is-after-start-time",
        "End time must be after start time",
        function (value) {
          const { startTime } = this.parent;
          return validateTimeOrder(startTime, value);
        }
      ),
    skillMatrix: Yup.array().min(1, "At least one skill is required"),
  });

  const calculateDuration = (startTime, endTime) => {
    if (!startTime || !endTime) return "";

    const start = new Date(`1970-01-01T${startTime}`);
    const end = new Date(`1970-01-01T${endTime}`);
    const durationInMinutes = (end - start) / (1000 * 60);

    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);

    return `${hours ? `${hours} hour${hours > 1 ? "s" : ""}` : ""}${
      hours && minutes ? " " : ""
    }${minutes ? `${minutes} minute${minutes > 1 ? "s" : ""}` : ""}`.trim();
  };

  const handleTabChange = (tab, direction) => {
    if (direction === "forward") {
      if (tab === "Personal Settings") {
        setActive("Skill Matrix");
      } else if (tab === "Skill Matrix") {
        setActive("Financials");
      } else {
        setActive(tab);
      }

      if (!completedTabs.includes(tab)) {
        setCompletedTabs((prevTabs) => [...prevTabs, tab]);
      }
    } else if (direction === "backward") {
      if (tab === "Skill Matrix") {
        setActive("Personal Settings");
      } else if (tab === "Financials") {
        setActive("Skill Matrix");
      }

      setCompletedTabs((prevTabs) => prevTabs.filter((t) => t !== tab));
    }
  };

  const getProfileFormData = async () => {
    try {
      const data = await getStudentProfile();

      if (data && Object.keys(data).length > 0) {
        const profileData = data.user;

        setCompletedTabs(StudentTab);

        if (profileData.startTime && profileData.endTime) {
          profileData.startTime = profileData.startTime
            ? dayjs(profileData.startTime).format("HH:mm")
            : "";
          profileData.endTime = profileData.endTime
            ? dayjs(profileData.endTime).format("HH:mm")
            : "";
        }

        if (profileData.profileImageUrl) {
          setPreview(data.user.profileImageUrl);
        }
        dispatch(updateStudentProfile(profileData));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getProfileFormData();
  }, []);

  const handleSubmit = (values) => {
    dispatch(updateStudentProfile(values));
    handleTabChange(active, "forward");
  };

  const handleSubmitskillMatrix = (values) => {
    dispatch(updateStudentSkillMatrix(values.skillMatrix));
    handleTabChange(active, "forward");
  };

  const handleSubmitForm = async () => {
    try {
      const formData = new FormData();
      formData.append("firstName", initialValues.firstName);
      formData.append("lastName", initialValues.lastName);
      formData.append("email", initialValues.email);
      formData.append("source", initialValues.source);
      formData.append("contactNumber", initialValues.contactNumber);
      if (Array.isArray(initialValues.areaOfInterest)) {
        initialValues.areaOfInterest.forEach((interest) => {
          formData.append("areaOfInterest[]", interest);
        });
      }
      formData.append("availability", initialValues.availability);
      formData.append("duration", initialValues.duration);
      formData.append("startTime", initialValues.startTime);
      formData.append("endTime", initialValues.endTime);
      formData.append("skillMatrix", JSON.stringify(initialValues.skillMatrix));
      if (file) {
        formData.append("profilePicture", file);
      }
      const data = await setupStudentProfile(formData);
      if (data) {
        showModal(data);
        getProfileFormData();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <div className="!flex bg-gray1 justify-end">
        <div>
          <Sidebar />
        </div>
        <div className="lg:!w-[calc(100%-280px)] w-full">
          <UserHeader />
          <div className="md:p-[34px_45px] sm:p-[25px_35px] p-[14px] h-[calc(100%-280px)]">
            <h1 className="text-[30px] font-medium text-nevyblue mb-[21px]">
              Settings
            </h1>

            <div className="overflow-auto">
              <ButtonGroup>
                {StudentTab.map((type) => (
                  <Tab
                    key={type}
                    active={active === type}
                    onClick={() => setActive(type)}
                    className="bg-white text-primary flex justify-between items-center gap-[25px] sm:text-[18px] text-[14px] font-medium p-[16px] w-full max-w-[245px] cursor-pointer border-0 outline-0 text-left whitespace-nowrap"
                  >
                    {type} {completedTabs.includes(type) && <BsCheckAll />}{" "}
                  </Tab>
                ))}
              </ButtonGroup>
            </div>

            {active === "Personal Settings" && (
              <div className="bg-white mt-1">
                <div className="flex justify-between items-center flex-wrap gap-[10px] sm:p-[30px] p-[22px_12px] border-b-[2px] border-b-[#F1F1F1]">
                  <h1 className="text-[20px] font-bold text-nevyblue">
                    Personal Settings
                  </h1>
                  <div className="flex items-center gap-[5px]">
                    <BiSolidCheckCircle className="text-secondary2" />
                    <p className="text-[14px] text-secondary2 font-normal mb-0">
                      All Updates Saved as Draft
                    </p>
                  </div>
                </div>

                <div>
                  <div>
                    <div className="flex sm:flex-row flex-col md:p-[30px_30px_0px_30px] p-[12px] lg:gap-[108px] gap-[38px] items-start">
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                          handleSubmit(values);
                        }}
                        enableReinitialize
                      >
                        {({
                          values,
                          setFieldValue,
                          validateField,
                          errors,
                          touched,
                          resetForm,
                          setTouched,
                          isSubmitting,
                        }) => {
                          const duration = calculateDuration(
                            values.startTime,
                            values.endTime
                          );

                          if (duration !== values.duration) {
                            setFieldValue("duration", duration);
                          }
                          return (
                            <Form className="w-full">
                              <div className="flex items-start justify-between sm:!flex-row !flex-col md:gap-[50px] lg:gap-[80px] xl:gap-[108px] gap-[50px]">
                                <div className="flex justify-start max-w-[170px] w-full relative">
                                  <label
                                    htmlFor="file-upload"
                                    className="relative cursor-pointer w-[170px] h-[170px] object-cover shadow-[0px_0px_4px_00000042] rounded-[10px] overflow-hidden border-2 border-gray-300 transition bg-white"
                                  >
                                    <img
                                      name="profilePicture"
                                      src={preview}
                                      alt="Profile Preview"
                                      className="rounded-[10px] w-full h-full object-cover"
                                    />

                                    <input
                                      name="profilePicture"
                                      id="file-upload"
                                      type="file"
                                      className="hidden"
                                      accept="image/*"
                                      // onChange={(e) =>
                                      //   handleFileChange(e, setFieldValue)
                                      // }
                                    />
                                    <button
                                      type="button"
                                      className="w-full text-[14px] font-bold text-white absolute bottom-0 py-2 flex gap-2 bg-[#000000cc] justify-center rounded-b-[10px]"
                                    >
                                      <div>
                                        <FaUpload />
                                      </div>
                                      Upload Photo
                                    </button>
                                  </label>

                                  {showCropper && image && (
                                    <div className="fixed inset-0 bg-opacity-50 flex items-center justify-center z-50">
                                      <div className="bg-white p-4 rounded-lg max-w-2xl w-full mx-4">
                                        <Cropper
                                          src={image}
                                          style={{ height: 400, width: "100%" }}
                                          aspectRatio={1}
                                          guides={true}
                                          onInitialized={(instance) =>
                                            setCropper(instance)
                                          }
                                        />
                                        <div className="flex justify-end gap-2 mt-4">
                                          <button
                                            type="button"
                                            onClick={() => {
                                              setShowCropper(false);
                                              setImage("");
                                            }}
                                            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            type="button"
                                            onClick={() =>
                                              getCropData(setFieldValue)
                                            }
                                            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                                          >
                                            Crop & Save
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  <ErrorMessage name="profilePicture">
                                    {(msg) => (
                                      <div className="text-red text-sm mt-1">
                                        {msg}
                                      </div>
                                    )}
                                  </ErrorMessage>
                                </div>

                                <div className="w-full">
                                  <div className="grid sm:grid-cols-2 grid-cols-1 gap-[20px]">
                                    <TextInput
                                      label="First Name"
                                      name="firstName"
                                      placeholder="First Name"
                                      isRequired={true}
                                    />

                                    <TextInput
                                      label="Last Name"
                                      name="lastName"
                                      placeholder="Last Name"
                                      isRequired={true}
                                    />

                                    <PhoneNumberInput
                                      label="Contact Number"
                                      name="contactNumber"
                                      value={values.contactNumber}
                                      onChange={setFieldValue}
                                      error={
                                        errors.contactNumber &&
                                        touched.contactNumber
                                      }
                                      isRequired={true}
                                    />

                                    <TextInput
                                      label="Email Address"
                                      name="email"
                                      type="email"
                                      placeholder="Username or email address"
                                      readOnly={true}
                                      isRequired={true}
                                    />

                                    <div>
                                      <SelectList
                                        label={"Source"}
                                        options={Source}
                                        value={values.source}
                                        onChange={(e) => {
                                          setFieldValue("source", e);
                                        }}
                                        name="source"
                                        placeholder="Select Source"
                                        error={errors.source && touched.source}
                                        isRequired={true}
                                      />
                                    </div>

                                    <div className="w-full">
                                      <SelectList
                                        label={"Area Of Interest"}
                                        options={Interest}
                                        value={values.interest}
                                        onChange={(e) => {
                                          setFieldValue("interest", e);
                                          handleAddInterest(
                                            e,
                                            values,
                                            setFieldValue,
                                            validateField
                                          );
                                        }}
                                        name="interest"
                                        placeholder="Select Area Of Interest"
                                        error={
                                          errors.interest && touched.interest
                                        }
                                        isRequired={true}
                                      />

                                      <div className="mt-[17px] flex gap-[12px] items-center flex-wrap">
                                        {values.areaOfInterest.map(
                                          (interest, index) => (
                                            <div
                                              key={index}
                                              className="flex items-center"
                                            >
                                              <button className="text-nevyblue px-[10px] text-[16px] font-medium flex justify-center gap-[10px] items-center border-[1.5px] border-[#273446] rounded-[6px] h-9">
                                                {interest}
                                                <MdOutlineCancel
                                                  className="text-red w-[16px] h-[16px] cursor-pointer"
                                                  onClick={() =>
                                                    handleRemoveInterest(
                                                      interest,
                                                      values,
                                                      setFieldValue,
                                                      validateField
                                                    )
                                                  }
                                                />
                                              </button>
                                            </div>
                                          )
                                        )}
                                        <ErrorMessage
                                          name="areaOfInterest"
                                          component="div"
                                          className="text-red text-sm mt-1"
                                        />{" "}
                                      </div>
                                    </div>

                                    <SelectList
                                      label={"Availability"}
                                      options={Availability}
                                      value={values.availability}
                                      onChange={(e) => {
                                        setFieldValue("availability", e);
                                      }}
                                      name="availability"
                                      placeholder="Select Availability"
                                      error={
                                        errors.availability &&
                                        touched.availability
                                      }
                                      isRequired={true}
                                    />
                                    {/* <TimePicker
                                      label="Start Time"
                                      value={values.startTime}
                                      onChange={(time) => {
                                        setFieldValue("startTime", time);
                                      }}
                                      onBlur={() =>
                                        setTouched({ startTime: true })
                                      }
                                      name="startTime"
                                      placeholder="Start Time"
                                      error={
                                        errors.startTime && touched.startTime
                                      }
                                      isRequired={true}
                                    /> */}
                                    <TextInput
                                      label="Start Time"
                                      name="startTime"
                                      type="time"
                                      placeholder="Start Time"
                                    />
                                    <TextInput
                                      label="End Time"
                                      name="endTime"
                                      type="time"
                                      placeholder="End Time"
                                    />
                                    {/* <TimePicker
                                      label="End Time"
                                      value={values.endTime}
                                      onChange={(time) => {
                                        setFieldValue("endTime", time);
                                      }}
                                      onBlur={() =>
                                        setTouched({ endTime: true })
                                      }
                                      name="endTime"
                                      placeholder="End Time"
                                      error={errors.endTime && touched.endTime}
                                      isRequired={true}
                                    /> */}
                                    <TextInput
                                      label="Duration"
                                      name="duration"
                                      placeholder="Enter Duration"
                                      isRequired={true}
                                    />
                                  </div>

                                  <div className="col-span-2 mt-5">
                                    <div className="w-full">
                                      <label className="text-[16px] font-normal text-black">
                                        Skills
                                        <span className="text-red text-[18px] pl-1">
                                          *
                                        </span>{" "}
                                      </label>
                                      <div className="mt-[5px]">
                                        <div className="relative border border-secondary2 rounded-[4px] flex items-center bg-white1 w-full">
                                          <Field
                                            name="skill"
                                            className="w-full p-[9px_16px] pr-[150px] placeholder:text-[14px] placeholder:text-secondary2 relative focus:outline-none"
                                            placeholder="Select a Skill"
                                          />
                                          <button
                                            type="button"
                                            className="absolute right-[16px] sm:text-[16px] text-[12px] font-medium text-primary"
                                            onClick={() => {
                                              if (values.skill) {
                                                handleAddSkill(
                                                  values.skill,
                                                  values,
                                                  setFieldValue,
                                                  validateField
                                                );
                                              }
                                            }}
                                          >
                                            + Add More
                                          </button>
                                        </div>
                                        <ErrorMessage
                                          name="skillMatrix"
                                          component="div"
                                          className="text-red text-sm mt-1"
                                        />
                                      </div>
                                      <div className="mt-[17px] flex gap-[12px] items-center flex-wrap">
                                        {values.skillMatrix
                                          .filter(
                                            (skill) => skill.category !== ""
                                          )
                                          .map((skill, index) => (
                                            <div
                                              key={index}
                                              className="flex items-center"
                                            >
                                              <button className="text-nevyblue px-[10px] text-[16px] font-medium flex justify-center gap-[10px] items-center border-[1.5px] border-[#273446] rounded-[6px] h-9">
                                                {skill.category}
                                                <MdOutlineCancel
                                                  className="text-red w-[16px] h-[16px] cursor-pointer"
                                                  onClick={() =>
                                                    handleRemoveSkill(
                                                      skill.category,
                                                      values,
                                                      setFieldValue,
                                                      validateField
                                                    )
                                                  }
                                                />
                                              </button>
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="flex justify-end sm:gap-[22px] gap-[12px] sm:p-[30px] p-[12px]">
                                <Button
                                  onClick={() => resetForm()}
                                  text="Reset"
                                  className="border-primary border-[2px] text-primary sm:px-[28px] px-[16px]"
                                />
                                <Button
                                  type="submit"
                                  disabled={isSubmitting}
                                  text="Save & Next"
                                  className="bg-primary text-white hover:bg-[#466cb7] sm:px-[28px] px-[16px]"
                                />
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {active === "Skill Matrix" && (
              <div className="bg-white mt-1">
                <div className="flex justify-between items-center flex-wrap gap-[10px] sm:p-[30px] p-[22px_12px] border-b-[2px] border-b-[#F1F1F1]">
                  <h1 className="text-[20px] font-bold text-nevyblue">
                    Skill Matrix
                  </h1>
                  <div className="flex items-center gap-[5px]">
                    <BiSolidCheckCircle className="text-secondary2" />
                    <p className="text-[14px] text-secondary2 font-normal mb-0">
                      Skill Matrix All Updates Saved as Draft
                    </p>
                  </div>
                </div>
                <SkillMatrixForm
                  initialValues={initialValues}
                  handleSubmit={handleSubmitskillMatrix}
                  handleTabChange={handleTabChange}
                  active={active}
                  role={"student"}
                />
              </div>
            )}

            {active === "Financials" && (
              <div className="bg-white mt-1 ">
                <div className="flex justify-between items-center flex-wrap gap-[10px] sm:p-[30px] p-[22px_12px] border-b-[2px] border-b-[#F1F1F1]">
                  <h1 className="text-[20px] font-bold text-nevyblue">
                    Financials
                  </h1>
                  <div className="flex items-center gap-[5px]">
                    <BiSolidCheckCircle className="text-secondary2" />
                    <p className="text-[14px] text-secondary2 font-normal mb-0">
                      All Updates Saved as Draft
                    </p>
                  </div>
                </div>
                <div>
                  <div className="sm:p-[30px] p-[12px]">
                    <div className="border border-[#ABAAAA] sm:p-[22px_38px_40px_38px] p-[16px]">
                      <h1 className="text-[24px] font-semibold text-black mb-[25px]">
                        Subscription Plan
                      </h1>
                      <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[36px]">
                        <div className="shadow-[0px_0px_12px_#8db3ff33] p-[21px_29px] bg-white border border-white rounded-lg hover:bg-[#8db3ff33] hover:border hover:border-[#002060]">
                          <PiCircleFill className="w-[25px] h-[25px] text-primary" />
                          <h1 className="font-medium text-[18px] text-black my-[13px]">
                            One Time Fee
                          </h1>
                          <p className="sm:text-[18px] text-[16px] font-normal text-lightgray2 mb-[14px]">
                            Pay and register for lifetime
                          </p>
                          <h2 className="text-[18px] font-normal text-primary">
                            5$
                          </h2>
                        </div>
                        <div className="shadow-[0px_0px_12px_#8db3ff33] p-[21px_29px] bg-white border border-white rounded-lg hover:bg-[#8db3ff33] hover:border hover:border-[#002060]">
                          <MdOutlineCalendarMonth className="w-[25px] h-[25px] text-primary" />
                          <h1 className="font-medium text-[18px] text-black my-[13px]">
                            Monthly
                          </h1>
                          <p className="sm:text-[18px] text-[16px] font-normal text-lightgray2 mb-[14px]">
                            Pay per Month for 5 months
                          </p>
                          <h2 className="text-[18px] font-normal text-primary">
                            1$
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="grid xl:grid-cols-2 grid-cols-1 gap-[24px]">
                      <div className="mt-[18px]">
                        <div className="border border-[#ABAAAA] sm:p-[36px] p-[16px]">
                          <h1 className="text-[24px] font-medium text-black mb-[19px]">
                            Cards
                          </h1>
                          <div>
                            <div>
                              <img
                                src={images[currentIndex]}
                                alt="Card"
                                className="w-full"
                              />
                            </div>
                            <div className="flex items-center justify-between mt-[19px]">
                              <div className="flex gap-[5px]">
                                {images.map((_, index) => (
                                  <span
                                    key={index}
                                    className={`w-3 h-3 rounded-full ${
                                      index === currentIndex
                                        ? "bg-primary"
                                        : "bg-lightblue"
                                    }`}
                                  ></span>
                                ))}
                              </div>

                              <div className="flex gap-[16px]">
                                <button
                                  onClick={prevImage}
                                  className="text-gray-400 hover:text-gray-600"
                                >
                                  <BsArrowLeft className="w-[22px] h-[22px]" />
                                </button>
                                <button
                                  onClick={nextImage}
                                  className="text-gray-400 hover:text-gray-600"
                                >
                                  <BsArrowRight className="w-[22px] h-[22px]" />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="w-full mt-[40px]">
                            <label
                              for="dropzone-file"
                              class="flex items-center justify-center w-full  border-[3px] border-[#ABAAAA] h-[147px] border-dashed "
                            >
                              <div class="flex items-center justify-center gap-2">
                                <AiOutlinePlusCircle className="w-[31px] h-[31px] text-primary" />
                                <p className="text-[18px] font-normal text-black">
                                  Add new card
                                </p>
                              </div>
                              <input
                                id="dropzone-file"
                                type="file"
                                class="hidden"
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="mt-[18px]">
                        <div className="border border-[#ABAAAA] sm:p-[36px_36px_132px_36px] p-[16px_16px_132px_16px]">
                          <h1 className="text-[24px] font-medium text-black mb-[13px]">
                            Payment Method
                          </h1>
                          <p className="text-[16px] font-normal text-secondary2 mb-[22px]">
                            Payment method:
                          </p>
                          <div>
                            <div className="w-full border-[2px] border-[#ABAAAA] p-[5px] group hover:border-[#36BF1B]">
                              <div className="flex sm:gap-[14px] gap-[12px] justify-between items-center  ">
                                <img
                                  src={VISA}
                                  alt=""
                                  className="sm:w-[60px] w-[38px] sm:h-[27px] h-[18px]"
                                />
                                <div className="flex gap-[17px]">
                                  <p className="sm:text-[14px] text-[10px] text-black font-normal mb-0 ">
                                    4855 **** **** ****
                                  </p>
                                  <p className="sm:text-[14px] text-[10px] text-black font-normal mb-0">
                                    04/24
                                  </p>
                                  <p className="sm:text-[14px] text-[10px] text-black font-normal mb-0 ">
                                    Revanth T
                                  </p>
                                </div>
                                <div className="w-[20px] h-[20px] rounded-full flex items-center justify-center group-hover:bg-[#36BF1B]">
                                  <BiCheck className=" text-white" />
                                </div>
                              </div>
                            </div>
                            <div className="w-full border-[2px] border-[#ABAAAA] p-[5px] group hover:border-[#36BF1B] mt-[25px]">
                              <div className="flex sm:gap-[14px] gap-[12px] justify-between items-center  ">
                                <img
                                  src={CARD}
                                  alt=""
                                  className="sm:w-[53px] w-[38px] sm:h-[27px] h-[23px]"
                                />
                                <div className="flex gap-[17px]">
                                  <p className="sm:text-[14px] text-[10px] text-black font-normal mb-0 ">
                                    4855 **** **** ****
                                  </p>
                                  <p className="sm:text-[14px] text-[10px] text-black font-normal mb-0 ">
                                    04/24
                                  </p>
                                  <p className="sm:text-[14px] text-[10px] text-black font-normal mb-0 ">
                                    Revanth T
                                  </p>
                                </div>
                                <div className="w-[20px] h-[20px] rounded-full flex items-center justify-center group-hover:bg-[#36BF1B]">
                                  <BiCheck className=" text-white" />
                                </div>
                              </div>
                            </div>
                            <div className="w-full border-[2px] border-[#ABAAAA] p-[5px] group hover:border-[#4a5648] mt-[25px]">
                              <div className="flex gap-[4px] justify-between items-center  ">
                                <img
                                  src={PAYPAL}
                                  alt=""
                                  className="sm:w-[29px] w-[22px] sm:h-[37px] h-[28px]"
                                />
                                <div>
                                  <p className="text-[11px] text-nevyblue font-normal mb-0">
                                    You will be redirected to the PayPal site
                                    after reviewing your order.
                                  </p>
                                </div>
                                <div className="w-[20px] h-[20px] rounded-full flex items-center justify-center group-hover:bg-[#36BF1B]">
                                  <BiCheck className=" text-white" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sm:p-[17px_35px] p-[23px_0]">
                          <div className="flex justify-between items-center">
                            <div>
                              <h1 className="sm:text-[36px] text-[26px] text-nevyblue font-normal">
                                $5
                              </h1>
                              <p className="sm:text-[20px] text-[16px] font-normal text-secondary2 mb-0">
                                Total
                              </p>
                            </div>
                            <button className="bg-primary text-white max-w-[120px] w-full h-[46px] flex justify-center items-center rounded sm:text-[20px] text-[16px]">
                              Pay Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex sm:justify-end justify-center sm:gap-[22px] gap-[12px] sm:p-[30px_30px_32px_30px] p-[12px]">
                    <Button
                      onClick={() => handleTabChange(active, "backward")}
                      text="Previous"
                      className="border-primary border-[2px] text-primary px-[28px] "
                    />
                    <Button
                      onClick={handleSubmitForm}
                      text="Submit"
                      className="border-primary border-[2px] text-primary w-full max-w-[224px] hover:bg-primary hover:text-white"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentProfile;
