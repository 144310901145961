import React from "react";
import CommonPart from "../../../components/CommonPart";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { forgotPassword } from "../../../services/apis/authentication";
import TextInput from "../../../components/common/TextInput";
import Button from "../../../components/common/Button";
import { useModal } from "../../../contexts/ModelContext";

const text = "Very Good work are waiting for you Login Now !!!";

function ForgotPassword() {
  const { showModal } = useModal();
  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const handleSubmit = async (formData, { resetForm }) => {
    try {
      const data = await forgotPassword(formData);
      if (data) {
        showModal(data);
      }
      resetForm();
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <div className="md:flex h-full min-h-screen">
      <CommonPart text={text} />
      <div className="flex justify-center items-center md:mx-auto py-[50px] px-[27px] md:w-1/2 h-screen overflow-auto">
        <div className="md:max-w-[347px] lg:max-w-[450px] xl:max-w-[642px] w-full bg-white shadow-[0px_0px_12px_#5c5ca133] mx-auto">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, resetForm }) => (
              <Form className="px-[20px] md:px-[20px] lg:px-[26px] xl:px-[56px] py-[34px]">
                <h1 className="text-[20px] md:text-[28px] lg:text-[35px] xl:text-[45px] font-bold text-center pb-[18px]">
                  Forgot Your Password
                </h1>
                <p className="text-center text-gray1 text-[14px] lg:text-[16px] pt-[14px] pb-[60px] max-w-[326px] w-full mx-auto">
                  No Worries , Just Enter Your Registered Email, And We Will
                  Assist You
                </p>
                <div className="xl:min-w-[530px]">
                  <TextInput
                    label="Email Address"
                    name="email"
                    type="email"
                    placeholder="Username or email address"
                    isRequired={true}
                  />
                </div>
                <div className="pt-[52px]">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    text="Reset Password"
                    className="bg-primary text-white hover:bg-[#466cb7] w-full"
                  />
                </div>
                <div className="pt-[20px]">
                  <Button
                    onClick={() => resetForm()}
                    text="Cancel"
                    className="border-primary border-[2px] text-primary w-full"
                  />
                </div>
                <div className="pt-[32px] text-center">
                  <p className="sm:text-[18px] text-[14px] text-gray1 pb-[8px]">
                    Already have an account ?
                    <span className="sm:text-[18px] text-[14px] text-primary">
                      <Link to="/login">&nbsp;Log In</Link>
                    </span>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
