import React, { useState, useRef } from "react";
import { BsDashLg, BsFileBarGraph } from "react-icons/bs";
import { CiVideoOn } from "react-icons/ci";

import { BiPlus, BiTime, BiSolidChevronDown } from "react-icons/bi";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { Form, Field, Formik } from "formik";
import { CheckIcon } from "@heroicons/react/20/solid";
import { IoIosArrowDown } from "react-icons/io";
import { CgSoftwareUpload } from "react-icons/cg";
import GALLERY from "../../../assets/Images/gallery.png";
import VIDEO from "../../../assets/Images/video.png";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ClassType, Session } from "../../../constant/dataConstant";
import Sidebar from "../../../components/profile/Sidebar";
import TabGroupButton from "../../../components/common/TabGroupButton";
import UserHeader from "../../../components/header/UserHeader";
import VideoModal from "../../../model/course/VideoModel";
import FileModal from "../../../model/course/FileModel";
import NotesModal from "../../../model/course/NoteModel";

const Artform = [
  {
    id: 0,
    name: "Select",
    value: "",
  },
  {
    id: 1,
    name: "Teacher",
    value: "teacher",
  },
];
const SkillLevel = [
  {
    id: 0,
    name: "Select",
    value: "",
  },
  {
    id: 1,
    name: "Teacher",
    value: "teacher",
  },
];
const Course = [
  {
    id: 0,
    name: "Select",
    value: "",
  },
  {
    id: 1,
    name: "Teacher",
    value: "teacher",
  },
];
const Optional = [
  {
    id: 0,
    name: "Select",
    value: "",
  },
  {
    id: 1,
    name: "Teacher",
    value: "teacher",
  },
];

const tabTypes = ["Basic Information", "Advance Information", "Curriculum"];

function NewCourse() {
  const [active, setActive] = useState(tabTypes[0]);
  const [charCount, setCharCount] = useState(0);
  const [charCount1, setCharCount1] = useState(0);
  const [charCount2, setCharCount2] = useState(0);
  const [charCount3, setCharCount3] = useState(0);
  const [charCount4, setCharCount4] = useState(0);
  const [charCount5, setCharCount5] = useState(0);
  const [selected, setSelected] = useState(SkillLevel[0]);
  const [selected1, setSelected1] = useState(Artform[0]);
  const [selected2, setSelected2] = useState(Session[0]);
  const [selected3, setSelected3] = useState(ClassType[0]);
  const [selected4, setSelected4] = useState(Course[0]);
  const [selected5, setSelected5] = useState(Optional[0]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [video, setVideo] = useState(false);
  const [file, setFile] = useState(false);
  const [notes, setNotes] = useState(false);
  const [value, setValue] = useState("");
  const [showContent, setShowContent] = useState(false);

  const handleClick = () => {
    setShowContent(!showContent);
  };

  const modules = {
    toolbar: [
      [
        "bold",
        "italic",
        "underline",
        "strike",
        "link",
        { list: "bullet" },
        { list: "ordered" },
      ],
    ],
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const maxChars = 80;

  const handleInputChange = (e) => {
    setCharCount(e.target.value.length);
  };

  const maxChars1 = 120;

  const handleInputChange1 = (e) => {
    setCharCount1(e.target.value.length);
  };

  const maxChars2 = 80;

  const handleInputChange2 = (e) => {
    setCharCount2(e.target.value.length);
  };

  const maxChars3 = 80;

  const handleInputChange3 = (e) => {
    setCharCount3(e.target.value.length);
  };

  const maxChars4 = 80;

  const handleInputChange4 = (e) => {
    setCharCount4(e.target.value.length);
  };

  const maxChars5 = 80;

  const handleInputChange5 = (e) => {
    setCharCount5(e.target.value.length);
  };
  return (
    <div>
      <div className="!flex bg-gray1 justify-end">
        <Sidebar />
        <div className="w-[calc(100%-280px)]">
          <UserHeader />
          <div className="md:p-[34px_45px] sm:p-[25px_35px] p-[16px] h-[calc(100%-280px)]">
            <div className="overflow-auto">
              <TabGroupButton
                tabTypes={tabTypes}
                active={active}
                setActive={setActive}
              />
            </div>

            {active === "Basic Information" && (
              <div className="bg-white mt-1">
                <div className="flex justify-between items-center flex-wrap gap-[10px] sm:p-[30px] p-[22px_12px] border-b-[2px] border-b-[#F1F1F1]">
                  <h1 className="sm:text-[22px] font-semibold text-black">
                    Basic Information
                  </h1>
                </div>
                <Formik>
                  <Form>
                    <div className="w-full sm:p-[30px] p-[12px]">
                      <div>
                        <div>
                          <label className="text-[16px] font-normal text-black">
                            Class Title
                          </label>

                          <div className="relative mt-[5px] border border-gray-300 rounded-[4px]">
                            <input
                              type="text"
                              id="class-title"
                              placeholder="Your class title"
                              maxLength={maxChars}
                              className="block w-full p-[12px_16px] h-[42px]  focus:outline-none placeholder:text-[14px] placeholder:text-secondary"
                              onChange={handleInputChange}
                            />

                            <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 text-sm">
                              {charCount}/{maxChars}
                            </div>
                          </div>
                        </div>
                        <div className="mt-[18px]">
                          <label className="text-[16px] font-normal text-black">
                            Subtittle
                          </label>

                          <div className="relative mt-[5px] border border-gray-300 rounded-[4px]">
                            <input
                              type="text"
                              id="class-title"
                              placeholder="Your Subtittle"
                              maxLength={maxChars1}
                              className="block w-full p-[12px_16px] h-[42px]  focus:outline-none placeholder:text-[14px] placeholder:text-secondary"
                              onChange={handleInputChange1}
                            />

                            <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 text-sm">
                              {charCount1}/{maxChars1}
                            </div>
                          </div>
                        </div>
                        <div className="mt-[18px]">
                          <label className="text-[16px] font-normal text-black">
                            Attendees
                          </label>
                          <div className="relative border border-gray-300 rounded-[4px] flex items-center bg-white1 w-full mt-[5px]">
                            <Field
                              className="w-full p-[12px_16px] h-[42px] pr-[150px] placeholder:text-[14px] placeholder:text-secondary relative focus:outline-none"
                              placeholder="Add Attendees"
                            />
                            <button
                              type="button"
                              className="absolute right-[16px] sm:text-[16px] text-[12px] font-medium text-primary"
                            >
                              Add/+
                            </button>
                          </div>
                        </div>
                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-[20px] mt-[18px]">
                          <div>
                            <label className="text-[16px] font-normal text-black mb-[5px]">
                              Session
                            </label>
                            <div className="mt-[5px]">
                              <Listbox
                                className=""
                                value={selected3}
                                onChange={(e) => {
                                  setSelected3(e);
                                }}
                              >
                                <div className="relative w-full">
                                  <ListboxButton className="relative w-full p-[0px_16px] h-[42px] border border-[#ABAAAA] rounded-[4px] placeholder:text-[14px] placeholder:text-secondary focus:outline-none bg-white">
                                    <span className="flex items-center">
                                      <span className="block truncate text-secondary">
                                        {selected3.name ||
                                          "Select a Subscription"}
                                      </span>
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 ml-3 flex items-center pr-2 right-[10px]">
                                      <IoIosArrowDown />
                                    </span>
                                  </ListboxButton>

                                  <ListboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {Session.map((person) => (
                                      <ListboxOption
                                        key={person.id}
                                        value={person}
                                        className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-primary data-[focus]:text-white"
                                      >
                                        <div className="flex items-center">
                                          <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                                            {person.name}
                                          </span>
                                        </div>

                                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                          <CheckIcon
                                            aria-hidden="true"
                                            className="h-5 w-5"
                                          />
                                        </span>
                                      </ListboxOption>
                                    ))}
                                  </ListboxOptions>
                                </div>
                              </Listbox>
                            </div>
                          </div>
                          <div>
                            <label className="text-[16px] font-normal text-black mb-[5px]">
                              Class Type
                            </label>
                            <div className="mt-[5px]">
                              <Listbox
                                className=""
                                value={selected2}
                                onChange={(e) => {
                                  setSelected2(e);
                                }}
                              >
                                <div className="relative w-full">
                                  <ListboxButton className="relative w-full p-[0px_16px] h-[42px] border border-[#ABAAAA] rounded-[4px] placeholder:text-[14px] placeholder:text-secondary focus:outline-none bg-white">
                                    <span className="flex items-center">
                                      <span className="block truncate text-secondary">
                                        {selected2.name ||
                                          "Select a Subscription"}
                                      </span>
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 ml-3 flex items-center pr-2 right-[10px]">
                                      <IoIosArrowDown />
                                    </span>
                                  </ListboxButton>

                                  <ListboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {ClassType.map((person) => (
                                      <ListboxOption
                                        key={person.id}
                                        value={person}
                                        className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-primary data-[focus]:text-white"
                                      >
                                        <div className="flex items-center">
                                          <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                                            {person.name}
                                          </span>
                                        </div>

                                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                          <CheckIcon
                                            aria-hidden="true"
                                            className="h-5 w-5"
                                          />
                                        </span>
                                      </ListboxOption>
                                    ))}
                                  </ListboxOptions>
                                </div>
                              </Listbox>
                            </div>
                          </div>
                          <div>
                            <label className="text-[16px] font-normal text-black mb-[5px]">
                              Artform
                            </label>
                            <div className="mt-[5px]">
                              <Listbox
                                className=""
                                value={selected1}
                                onChange={(e) => {
                                  setSelected1(e);
                                }}
                              >
                                <div className="relative w-full">
                                  <ListboxButton className="relative w-full p-[0px_16px] h-[42px] border border-[#ABAAAA] rounded-[4px] placeholder:text-[14px] placeholder:text-secondary focus:outline-none bg-white">
                                    <span className="flex items-center">
                                      <span className="block truncate text-secondary">
                                        {selected1.name ||
                                          "Select a Subscription"}
                                      </span>
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 ml-3 flex items-center pr-2 right-[10px]">
                                      <IoIosArrowDown />
                                    </span>
                                  </ListboxButton>

                                  <ListboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {Artform.map((person) => (
                                      <ListboxOption
                                        key={person.id}
                                        value={person}
                                        className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-primary data-[focus]:text-white"
                                      >
                                        <div className="flex items-center">
                                          <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                                            {person.name}
                                          </span>
                                        </div>

                                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                          <CheckIcon
                                            aria-hidden="true"
                                            className="h-5 w-5"
                                          />
                                        </span>
                                      </ListboxOption>
                                    ))}
                                  </ListboxOptions>
                                </div>
                              </Listbox>
                            </div>
                          </div>
                          <div>
                            <label className="text-[16px] font-normal text-black mb-[5px]">
                              Skill Level
                            </label>
                            <div className="mt-[5px]">
                              <Listbox
                                className=""
                                value={selected}
                                onChange={(e) => {
                                  setSelected(e);
                                }}
                              >
                                <div className="relative w-full">
                                  <ListboxButton className="relative w-full p-[0px_16px] h-[42px] border border-[#ABAAAA] rounded-[4px] placeholder:text-[14px] placeholder:text-secondary focus:outline-none bg-white">
                                    <span className="flex items-center">
                                      <span className="block truncate text-secondary">
                                        {selected.name ||
                                          "Select a Subscription"}
                                      </span>
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 ml-3 flex items-center pr-2 right-[10px]">
                                      <IoIosArrowDown />
                                    </span>
                                  </ListboxButton>

                                  <ListboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {SkillLevel.map((person) => (
                                      <ListboxOption
                                        key={person.id}
                                        value={person}
                                        className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-primary data-[focus]:text-white"
                                      >
                                        <div className="flex items-center">
                                          <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                                            {person.name}
                                          </span>
                                        </div>

                                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                          <CheckIcon
                                            aria-hidden="true"
                                            className="h-5 w-5"
                                          />
                                        </span>
                                      </ListboxOption>
                                    ))}
                                  </ListboxOptions>
                                </div>
                              </Listbox>
                            </div>
                          </div>

                          <div className=" w-full ">
                            <label className="text-[16px] font-normal text-black">
                              Date
                            </label>
                            <div className="relative p-[12px_16px] border border-gray-300 rounded-[4px] mt-[5px]">
                              <Field
                                className="w-full  placeholder:text-[14px] placeholder:text-secondary focus:outline-none"
                                placeholder="Select Date"
                              />
                              <BiTime className="absolute right-[16px] top-[12px] w-[22px] h-[22px]" />
                            </div>
                          </div>

                          <div className=" w-full ">
                            <label className="text-[16px] font-normal text-black">
                              Time
                            </label>
                            <div className="relative p-[12px_16px] border border-gray-300 rounded-[4px] mt-[5px]">
                              <Field
                                className="w-full  placeholder:text-[14px] placeholder:text-secondary focus:outline-none"
                                placeholder="Select Time"
                              />
                              <BiTime className="absolute right-[16px] top-[12px] w-[22px] h-[22px]" />
                            </div>
                          </div>
                          <div>
                            <label className="text-[16px] font-normal text-black mb-[5px]">
                              Course Language
                            </label>
                            <div className="mt-[5px]">
                              <Listbox
                                className=""
                                value={selected4}
                                onChange={(e) => {
                                  setSelected4(e);
                                }}
                              >
                                <div className="relative w-full">
                                  <ListboxButton className="relative w-full p-[0px_16px] h-[42px] border border-[#ABAAAA] rounded-[4px] placeholder:text-[14px] placeholder:text-secondary focus:outline-none bg-white">
                                    <span className="flex items-center">
                                      <span className="block truncate text-secondary">
                                        {selected4.name ||
                                          "Select a Subscription"}
                                      </span>
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 ml-3 flex items-center pr-2 right-[10px]">
                                      <IoIosArrowDown />
                                    </span>
                                  </ListboxButton>

                                  <ListboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {Course.map((person) => (
                                      <ListboxOption
                                        key={person.id}
                                        value={person}
                                        className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-primary data-[focus]:text-white"
                                      >
                                        <div className="flex items-center">
                                          <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                                            {person.name}
                                          </span>
                                        </div>

                                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                          <CheckIcon
                                            aria-hidden="true"
                                            className="h-5 w-5"
                                          />
                                        </span>
                                      </ListboxOption>
                                    ))}
                                  </ListboxOptions>
                                </div>
                              </Listbox>
                            </div>
                          </div>
                          <div>
                            <label className="text-[16px] font-normal text-black mb-[5px]">
                              Language (Optional)
                            </label>
                            <div className="mt-[5px]">
                              <Listbox
                                className=""
                                value={selected5}
                                onChange={(e) => {
                                  setSelected5(e);
                                }}
                              >
                                <div className="relative w-full">
                                  <ListboxButton className="relative w-full p-[0px_16px] h-[42px] border border-[#ABAAAA] rounded-[4px] placeholder:text-[14px] placeholder:text-secondary focus:outline-none bg-white">
                                    <span className="flex items-center">
                                      <span className="block truncate text-secondary">
                                        {selected5.name ||
                                          "Select a Subscription"}
                                      </span>
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 ml-3 flex items-center pr-2 right-[10px]">
                                      <IoIosArrowDown />
                                    </span>
                                  </ListboxButton>

                                  <ListboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {Optional.map((person) => (
                                      <ListboxOption
                                        key={person.id}
                                        value={person}
                                        className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-primary data-[focus]:text-white"
                                      >
                                        <div className="flex items-center">
                                          <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                                            {person.name}
                                          </span>
                                        </div>

                                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                          <CheckIcon
                                            aria-hidden="true"
                                            className="h-5 w-5"
                                          />
                                        </span>
                                      </ListboxOption>
                                    ))}
                                  </ListboxOptions>
                                </div>
                              </Listbox>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-[28px]">
                        <div className="flex sm:gap-[12px] gap-[7px]">
                          <input
                            type="checkbox"
                            className="w-[22px] h-[22px] rounded-[4px] bg-primary flex justify-center items-center accent-primary "
                          />
                          <h1 className="sm:text-[16px] text-[12px] font-normal text-black">
                            Recurrence :
                            <span className="sm:text-[16px] text-[12px] font-normal text-secondary">
                              Automated reminders for upcoming classes,
                              deadlines, and meetings.
                            </span>
                          </h1>
                        </div>
                        <div className="flex sm:gap-[12px] gap-[7px] mt-[15px]">
                          <input
                            type="checkbox"
                            className="w-[22px] h-[22px] rounded-[4px] bg-primary flex justify-center items-center accent-primary "
                          />
                          <h1 className="sm:text-[16px] text-[12px] font-normal text-black">
                            Sync to calendar :
                            <span className="sm:text-[16px] text-[12px] font-normal text-secondary">
                              Ensures all team members have up-to-date
                              schedules, improving coordination and reducing
                              missed meetings
                            </span>
                          </h1>
                        </div>
                      </div>

                      <div className="flex sm:justify-end justify-center sm:gap-[22px] gap-[12px] sm:mt-[68px] mt-[48px]">
                        <button
                          type="button"
                          className="  border-[2px] border-[#002060] text-primary hover:bg-primary w-full   max-w-[130px] h-[48px] flex items-center justify-center text-[18px] font-bold hover:text-white rounded-[4px]"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className=" border-[2px] border-[#002060] text-primary hover:bg-primary   w-full max-w-[168px] h-[48px] flex items-center justify-center text-[18px] font-bold hover:text-white rounded-[4px]"
                        >
                          Save & Next
                        </button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            )}

            {active === "Advance Information" && (
              <div className="bg-white mt-1">
                <div className="flex justify-between items-center flex-wrap gap-[10px] sm:p-[30px] p-[22px_12px] border-b-[2px] border-b-[#F1F1F1]">
                  <h1 className="sm:text-[22px] font-semibold text-black">
                    Advance Information
                  </h1>
                </div>
                <Formik>
                  <Form>
                    <div className="w-full sm:p-[30px] p-[12px]">
                      <div>
                        <h1 className="text-[18px] text-black font-medium mb-[20px]">
                          Course Thumbnail
                        </h1>
                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-[30px]">
                          <div className="flex gap-[12px] xl:flex-row flex-col">
                            <div className="xl:max-w-[170px] max-w-full w-full h-[116px] bg-lightgray flex justify-center items-center">
                              <img src={GALLERY} alt="" />
                            </div>
                            <div className="flex flex-col justify-between gap-[20px]">
                              <p className="text-[12px] font-normal text-secondary">
                                Upload your course Thumbnail here.
                                <span className="text-[12px] font-medium text-primary">
                                  Important guidelines:
                                </span>
                                1200 x 800 pixels or 12:8 Ratio. Supported
                                format:
                                <span className="text-[12px] font-medium text-primary">
                                  .jpg, .jpeg, or .png
                                </span>
                              </p>
                              <button className="text-[14px] font-medium text-primary bg-lightpurple w-[152px] h-[42px] flex justify-center items-center gap-[8px]">
                                <CgSoftwareUpload className="w-[20px] h-[20px]" />
                                <p className="mb-0">Upload Image</p>
                              </button>
                            </div>
                          </div>
                          <div className="flex gap-[12px] xl:flex-row flex-col">
                            <div className="xl:max-w-[170px] max-w-full w-full h-[116px] bg-lightgray flex justify-center items-center">
                              <img src={VIDEO} alt="" />
                            </div>
                            <div className="flex flex-col justify-between gap-[20px]">
                              <p className="text-[12px] font-normal text-secondary">
                                Students who watch a well-made promo video are
                                5X more likely to enroll in your course. We've
                                seen that statistic go up to 10X for
                                exceptionally awesome videos.
                              </p>
                              <button className="text-[14px] font-medium text-primary bg-lightpurple w-[152px] h-[42px] flex justify-center items-center gap-[8px]">
                                <CgSoftwareUpload className="w-[20px] h-[20px]" />
                                <p className="mb-0">Upload Video</p>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-[32px]">
                        <h1 className="text-[18px] text-black font-medium mb-[20px]">
                          Class Descriptions
                        </h1>
                        <div>
                          <ReactQuill
                            value={value}
                            onChange={setValue}
                            modules={modules}
                            placeholder="Enter your course descriptions"
                            className="bg-white rounded-lg"
                          />
                        </div>
                      </div>

                      <div className="mt-[32px]">
                        <div className="flex gap-[10px] justify-between sm:mb-[22px] mb-[35px]">
                          <h1 className="sm:text-[18px] text-[14px] text-black font-medium">
                            What you will teach in this course (4/8)
                          </h1>
                          <button className="flex gap-[5px] sm:items-center items-start">
                            <BiPlus className="text-primary" />
                            <h1 className="text-[14px] font-medium text-primary whitespace-nowrap">
                              Add More
                            </h1>
                          </button>
                        </div>
                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-[20px]">
                          <div>
                            <label className="text-[16px] font-normal text-black">
                              01
                            </label>

                            <div className="relative mt-[5px] border border-gray-300 rounded-[4px]">
                              <input
                                type="text"
                                id="class-title"
                                placeholder="What you will teach in this course..."
                                maxLength={maxChars2}
                                className="block w-full p-[12px_16px] h-[42px]  focus:outline-none placeholder:text-[14px] placeholder:text-secondary"
                                onChange={handleInputChange2}
                              />

                              <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 text-sm">
                                {charCount2}/{maxChars2}
                              </div>
                            </div>
                          </div>
                          <div>
                            <label className="text-[16px] font-normal text-black">
                              02
                            </label>

                            <div className="relative mt-[5px] border border-gray-300 rounded-[4px]">
                              <input
                                type="text"
                                id="class-title"
                                placeholder="What you will teach in this course..."
                                maxLength={maxChars3}
                                className="block w-full p-[12px_16px] h-[42px]  focus:outline-none placeholder:text-[14px] placeholder:text-secondary"
                                onChange={handleInputChange3}
                              />

                              <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 text-sm">
                                {charCount3}/{maxChars3}
                              </div>
                            </div>
                          </div>
                          <div>
                            <label className="text-[16px] font-normal text-black">
                              03
                            </label>

                            <div className="relative mt-[5px] border border-gray-300 rounded-[4px]">
                              <input
                                type="text"
                                id="class-title"
                                placeholder="What you will teach in this course..."
                                maxLength={maxChars4}
                                className="block w-full p-[12px_16px] h-[42px]  focus:outline-none placeholder:text-[14px] placeholder:text-secondary"
                                onChange={handleInputChange4}
                              />

                              <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 text-sm">
                                {charCount4}/{maxChars4}
                              </div>
                            </div>
                          </div>
                          <div className="">
                            <label className="text-[16px] font-normal text-black">
                              04
                            </label>

                            <div className="relative mt-[5px] border border-gray-300 rounded-[4px]">
                              <input
                                type="text"
                                id="class-title"
                                placeholder="What you will teach in this course..."
                                maxLength={maxChars5}
                                className="block w-full p-[12px_16px] h-[42px]  focus:outline-none placeholder:text-[14px] placeholder:text-secondary"
                                onChange={handleInputChange5}
                              />

                              <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 text-sm">
                                {charCount5}/{maxChars5}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex sm:justify-end justify-center sm:gap-[22px] gap-[12px] sm:mt-[68px] mt-[48px]">
                        <button
                          type="button"
                          className="  border-[2px] border-[#002060] text-primary hover:bg-primary w-full   max-w-[130px] h-[48px] flex items-center justify-center text-[18px] font-bold hover:text-white rounded-[4px]"
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className=" border-[2px] border-[#002060] text-primary hover:bg-primary   w-full max-w-[168px] h-[48px] flex items-center justify-center text-[18px] font-bold hover:text-white rounded-[4px]"
                        >
                          Save & Next
                        </button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            )}

            {active === "Curriculum" && (
              <div className="bg-white mt-1 ">
                <div className="flex justify-between items-center flex-wrap gap-[10px] sm:p-[30px] p-[22px_12px] border-b-[2px] border-b-[#F1F1F1]">
                  <h1 className="sm:text-[22px] font-semibold text-black">
                    Course Curriculum
                  </h1>
                </div>
                <Formik>
                  <Form>
                    <div className="w-full sm:p-[30px] p-[12px]">
                      <div className="sm:p-[30px] p-[12px] bg-[#F9F9F9]">
                        <h1 className="text-[16px] font-semibold text-lightgray1 mb-[20px]">
                          Beginner
                        </h1>
                        <div className="w-full bg-white p-[10px_170px_10px_16px] relative">
                          <div>
                            <div className="w-full flex gap-[10px] items-center">
                              <div
                                className="flex justify-center items-center rounded-full bg-lightblue1 w-[40px] h-[40px]"
                                onClick={handleClick}
                              >
                                {showContent ? (
                                  <BsDashLg className="text-primary w-[22px] h-[22px]" />
                                ) : (
                                  <BiPlus className="text-primary w-[22px] h-[22px]" />
                                )}
                              </div>
                              <Field
                                type="text"
                                className="w-full sm:text-[16px] text-[12px] font-semibold text-black placeholder:text-[16px] sm:placeholder:text-[12px] placeholder:font-semibold placeholder:text-black focus:outline-none"
                                placeholder="Tabla"
                              />
                            </div>

                            <div className="absolute top-[10px] right-[10px]">
                              <div
                                className="relative inline-block text-left"
                                ref={dropdownRef}
                              >
                                <button
                                  type="button"
                                  className="inline-flex justify-center sm:w-[153px] w-[116px] sm:h-[42px] h-[36px] sm:text-[14px] text-[12px] font-medium bg-lightpurple border-0 text-primary items-center gap-[12px]"
                                  id="menu-button"
                                  onClick={toggleDropdown}
                                >
                                  Contents
                                  <BiSolidChevronDown className="w-[20px] h-[20px]" />
                                </button>

                                {isOpen && (
                                  <div
                                    className="origin-top-right absolute right-0 mt-2 w-[205px] shadow-[0px_4px_4px_#00000040] border border-[#ABAAAA] bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    role="menu"
                                    aria-orientation="vertical"
                                    aria-labelledby="menu-button"
                                  >
                                    <div className="p-[12px]" role="none">
                                      <div
                                        className="pb-[12px]"
                                        onClick={() => setVideo(true)}
                                      >
                                        <Link
                                          to="#"
                                          className="text-[16px] text-normal font-normal"
                                        >
                                          Video
                                        </Link>
                                      </div>
                                      <div
                                        className="pb-[12px]"
                                        onClick={() => setFile(true)}
                                      >
                                        <Link
                                          to="#"
                                          className="text-[16px] text-normal font-normal"
                                        >
                                          Attach File
                                        </Link>
                                      </div>
                                      <div
                                        className=""
                                        onClick={() => setNotes(true)}
                                      >
                                        <Link
                                          to="#"
                                          className="text-[16px] text-normal font-normal"
                                        >
                                          Lecture Notes
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <VideoModal
                            open={video}
                            onClose={() => setVideo(false)}
                          />
                          <FileModal
                            open={file}
                            onClose={() => setFile(false)}
                          />
                          <NotesModal
                            open={notes}
                            onClose={() => setNotes(false)}
                          />
                        </div>
                        <div className="w-full bg-white pb-[20px] pr-[16px] ">
                          {showContent && (
                            <div className="sm:pl-[63px] pl-[16px]">
                              <div>
                                <h1 className="text-[16px] font-normal text-black">
                                  Attach File
                                </h1>
                                <div className="flex justify-between items-center gap-[7px] sm:pl-[23px] pl-[0] mt-[12px]">
                                  <div className="text-[14px] font-normal text-nevyblue flex items-center gap-[7px] ">
                                    <BsFileBarGraph className="w-[25px] h-[25px]" />
                                    Tabla file
                                  </div>
                                  <div className="flex items-center sm:gap-[23px] gap-[6px]">
                                    <button className="text-[14px] font-normal text-nevyblue bg-transparent border-0">
                                      View
                                    </button>
                                    <button className="text-[14px] font-normal text-nevyblue bg-transparent border-0">
                                      Download
                                    </button>
                                  </div>
                                </div>
                                <div className="mt-[15px]">
                                  <h1 className="text-[16px] font-normal text-black">
                                    Attach File
                                  </h1>
                                  <div className="flex justify-between items-center gap-[7px] sm:pl-[23px] pl-[0] mt-[12px]">
                                    <div className="text-[14px] font-normal text-nevyblue flex items-center gap-[7px] ">
                                      <CiVideoOn className="w-[25px] h-[25px]" />
                                      Tabla file
                                    </div>
                                    <div className="flex items-center sm:gap-[23px] gap-[6px]">
                                      <button className="text-[14px] font-normal text-nevyblue bg-transparent border-0">
                                        View
                                      </button>
                                      <button className="text-[14px] font-normal text-nevyblue bg-transparent border-0">
                                        Download
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex sm:justify-end justify-center sm:gap-[22px] gap-[12px] sm:mt-[68px] mt-[48px]">
                        <button
                          type="button"
                          className="  border-[2px] border-[#002060] text-primary hover:bg-primary w-full   max-w-[130px] h-[48px] flex items-center justify-center text-[18px] font-bold hover:text-white rounded-[4px]"
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className=" border-[2px] border-[#002060] text-primary hover:bg-primary   w-full max-w-[168px] h-[48px] flex items-center justify-center text-[18px] font-bold hover:text-white rounded-[4px]"
                        >
                          Save & Next
                        </button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewCourse;
