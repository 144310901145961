import React, { useState } from "react";
import { VscClose } from "react-icons/vsc";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";

import "react-quill/dist/quill.snow.css";

function FileModal({ open, onClose }) {
  const [file, setFile] = useState(false);
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        className="relative z-10"
        id="exampleModal"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-[#0000004d] transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 flex items-center justify-center p-4">
          {/* <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"> */}
          <DialogPanel
            transition
            className="relative transform overflow-hidden bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-full max-w-[743px] data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="flex justify-between items-center sm:p-[20px] p-[11px] border-b border-b-[#E3E2E2]">
              <h1 className="sm:text-[18px] text-[14px] font-normal text-black">
                Attach File
              </h1>
              <VscClose
                data-twe-target="#exampleModal"
                className="w-[24px] h-[24px] text-black cursor-pointer"
                onClick={onClose}
              />
            </div>
            <div className="sm:p-[33px_20px] p-[11px_11px_33px_11px]">
              <div>
                <label
                  htmlFor="uploadFile1"
                  className="bg-white h-[130px]  flex flex-col items-center justify-center cursor-pointer border-[#ABAAAA] border"
                >
                  <h1 className="sm:text-[18px] text-[14px] font-medium text-black">
                    Attach File
                  </h1>
                  <input type="file" id="uploadFile1" className="hidden" />
                  <p className="sm:text-[16px] text-[12px] font-medium text-secondary2">
                    Drag and drop a file or{" "}
                    <span className="text-nevyblue">browse file</span>
                  </p>
                </label>
              </div>
              <div className="flex sm:justify-end justify-center sm:gap-[22px] gap-[12px] mt-[31px]">
                <button
                  type="button"
                  className="border-[2px] border-[#002060] text-primary hover:bg-primary w-full max-w-[130px] sm:h-[48px] h-[33px] flex items-center justify-center sm:text-[18px] text-[14px] font-bold hover:text-white rounded-[4px]"
                  onClick={() => setFile(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="border-[2px] border-[#002060] text-primary hover:bg-primary w-full max-w-[168px] sm:h-[48px] h-[33px] flex items-center justify-center sm:text-[18px] text-[14px] font-bold hover:text-white rounded-[4px]"
                >
                  Attach File
                </button>
              </div>
            </div>
          </DialogPanel>
          {/* </div> */}
        </div>
      </Dialog>
    </>
  );
}

export default FileModal;
