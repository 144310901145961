import YOUNGMAN1 from "../assets/Images/youngman1.png";
import YOUNGMAN2 from "../assets/Images/youngman2.png";
import YOUNGMAN3 from "../assets/Images/youngman3.png";
import YOUNGMAN4 from "../assets/Images/youngman4.png";
import YOUNGMAN5 from "../assets/Images/youngman5.png";
import YOUNGMAN6 from "../assets/Images/youngman6.png";
import YOUNGMAN7 from "../assets/Images/youngman7.png";
import YOUNGMAN8 from "../assets/Images/youngman8.png";
import YOUNGMAN9 from "../assets/Images/youngman9.png";

export const Subscription = [
    {
        id: 1,
        name: "Silver",
        value: "silver",
    },
    {
        id: 2,
        name: "Platinum",
        value: "platinum",
    },
    {
        id: 3,
        name: "Gold",
        value: "gold",
    },
];

export const Availability = [
    {
        id: 1,
        name: "UTC (Coordinated Universal Time)",
        value: "UTC",
    },
    {
        id: 2,
        name: "IST (Indian Standard Time, UTC+5:30)",
        value: "Asia/Kolkata",
    },
    {
        id: 3,
        name: "EST (Eastern Standard Time, UTC-5)",
        value: "America/New_York",
    },
    {
        id: 4,
        name: "PST (Pacific Standard Time, UTC-8)",
        value: "America/Los_Angeles",
    },
    {
        id: 5,
        name: "GMT (Greenwich Mean Time, UTC+0)",
        value: "GMT",
    },
    {
        id: 6,
        name: "JST (Japan Standard Time, UTC+9)",
        value: "Asia/Tokyo",
    },
    {
        id: 7,
        name: "AEST (Australian Eastern Standard Time, UTC+10)",
        value: "Australia/Sydney",
    },
    {
        id: 8,
        name: "CST (Central Standard Time, UTC-6)",
        value: "America/Chicago",
    },
    {
        id: 9,
        name: "MST (Mountain Standard Time, UTC-7)",
        value: "America/Denver",
    },
    {
        id: 10,
        name: "CET (Central European Time, UTC+1)",
        value: "Europe/Paris",
    },
    {
        id: 11,
        name: "BST (British Summer Time, UTC+1)",
        value: "Europe/London",
    },
    {
        id: 12,
        name: "SGT (Singapore Time, UTC+8)",
        value: "Asia/Singapore",
    },
    {
        id: 13,
        name: "CST (China Standard Time, UTC+8)",
        value: "Asia/Shanghai",
    },
    {
        id: 14,
        name: "NZST (New Zealand Standard Time, UTC+12)",
        value: "Pacific/Auckland",
    },
    {
        id: 15,
        name: "BRT (Brasilia Time, UTC-3)",
        value: "America/Sao_Paulo",
    },
    {
        id: 16,
        name: "SAST (South African Standard Time, UTC+2)",
        value: "Africa/Johannesburg",
    },
    {
        id: 17,
        name: "MSK (Moscow Standard Time, UTC+3)",
        value: "Europe/Moscow",
    },
    {
        id: 18,
        name: "KST (Korean Standard Time, UTC+9)",
        value: "Asia/Seoul",
    },
    {
        id: 19,
        name: "GST (Gulf Standard Time, UTC+4)",
        value: "Asia/Dubai",
    },
    {
        id: 20,
        name: "PHT (Philippine Time, UTC+8)",
        value: "Asia/Manila",
    },
];

export const TeachersTab = ["Personal Settings", "Skill Matrix", "Fee Structure"];
export const StudentTab = ["Personal Settings", "Skill Matrix", "Financials"];

export const Coupon = [
    {
        id: 1,
        name: "Yes",
        value: true,
    },
    {
        id: 2,
        name: "No",
        value: false,
    },
];

export const Source = [
    { id: 1, name: "Google", value: "google" },
    { id: 2, name: "Friend or Family", value: "friend_or_family" },
    { id: 3, name: "Blog", value: "blog" },
    { id: 4, name: "Advertisement", value: "advertisement" },
    { id: 5, name: "Referral", value: "referral" },
    { id: 6, name: "Other", value: "other" },
];

export const SkillLevel = [
    {
        id: 1,
        name: "Beginner",
        value: "beginner",
    },
    {
        id: 2,
        name: "Intermediate",
        value: "intermediate",
    },
    {
        id: 3,
        name: "Advanced",
        value: "advanced",
    },
];

export const Role = [
    {
        id: 1,
        name: "Teacher",
        value: "teacher",
    },
    {
        id: 2,
        name: "Student",
        value: "student",
    },
];

export const Interest = [
    { id: 1, name: "Music", value: "music" },
    { id: 2, name: "Dancing", value: "dancing" },
    { id: 3, name: "Singing", value: "singing" },
    { id: 4, name: "Painting", value: "painting" },
    { id: 5, name: "Drawing", value: "drawing" },
    { id: 6, name: "Photography", value: "photography" },
    { id: 7, name: "Sports", value: "sports" },
    { id: 8, name: "Cooking", value: "cooking" },
    { id: 10, name: "Reading", value: "reading" },
    { id: 11, name: "Writing", value: "writing" },
    { id: 12, name: "Technology", value: "technology" },
    { id: 13, name: "Gardening", value: "gardening" },
    { id: 14, name: "Crafting", value: "crafting" },
    { id: 15, name: "Fitness", value: "fitness" },
    { id: 16, name: "Volunteering", value: "volunteering" },
    { id: 17, name: "Other", value: "other" },
];

export const userData = [
    {
        id: 1,
        name: "Riyas",
        specialty: "Ballet",
        level: "Intermediate & Advance",
        rating: 5.0,
        students: 236568,
        price: 15,
        image: YOUNGMAN1,
    },
    {
        id: 2,
        name: "Revanth Thiyagarajan",
        specialty: "Specialty: Contemporary Dance",
        level: "Intermediate & Advance",
        rating: 5.0,
        students: 236568,
        price: 12,
        image: YOUNGMAN2,
    },
    {
        id: 3,
        name: "Iyyappan",
        specialty: "Specialty: Tap Dance, Ballroom Dance",
        level: "Intermediate & Advance",
        rating: 5.0,
        students: 236568,
        price: 12,
        image: YOUNGMAN3,
    },
    {
        id: 4,
        name: "Abi",
        specialty: "Hip-Hop, Pop Dance",
        level: "Intermediate & Advance",
        rating: 5.0,
        students: 236568,
        price: 12,
        image: YOUNGMAN4,
    },
    {
        id: 5,
        name: "Divya",
        specialty: "Specialty: Latin Dance, Belly Dance",
        level: "Intermediate & Advance",
        rating: 5.0,
        students: 236568,
        price: 12,
        image: YOUNGMAN5,
    },
    {
        id: 6,
        name: "Vijay",
        specialty: "Specialty: Musical Theatre, Tap Dance",
        level: "Intermediate & Advance",
        rating: 5.0,
        students: 236568,
        price: 12,
        image: YOUNGMAN6,
    },
    {
        id: 7,
        name: "Dinesh",
        specialty: "Specialty: Modern Dance, Dance Theatre",
        level: "Intermediate & Advance",
        rating: 5.0,
        students: 236568,
        price: 12,
        image: YOUNGMAN7,
    },
    {
        id: 8,
        name: "Babu",
        specialty: "Specialty: Tap Dance",
        level: "Intermediate & Advance",
        rating: 5.0,
        students: 236568,
        price: 12,
        image: YOUNGMAN8,
    },
    {
        id: 9,
        name: "Alvin Ailey",
        specialty: "Specialty: Tap Dance",
        level: "Beginner",
        rating: 5.0,
        students: 236568,
        price: 12,
        image: YOUNGMAN9,
    },
];

export const Session = [
    {
        id: 1,
        name: "Regular class",
        value: "regular_class",
    },
    {
        id: 2,
        name: "Makeup Class",
        value: "makeup_class",
    },
];

export const ClassType = [
    {
        id: 1,
        name: "Individual",
        value: "individual",
    },
    {
        id: 2,
        name: "Group",
        value: "group",
    },
];