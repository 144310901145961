import React, { useState } from "react";
import Sidebar from "../../../components/profile/Sidebar";
import UserHeader from "../../../components/header/UserHeader";
import { CheckIcon } from "@heroicons/react/20/solid";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import MyCalendar from "../../.././model/course/Calender";
import { IoIosArrowDown } from "react-icons/io";
import { Form, Formik } from "formik";
import { Session, SkillLevel } from "../../../constant/dataConstant";
import { Link } from "react-router-dom";

function MySchedule() {
  const [selected, setSelected] = useState(SkillLevel[0]);
  const [selected1, setSelected1] = useState(Session[0]);
  return (
    <div>
      <div className="!flex bg-gray1 justify-end">
        <Sidebar />
        <div className="w-[calc(100%-280px)]">
          <UserHeader />
          <div className="md:p-[34px_45px] sm:p-[25px_35px] p-[16px] h-[calc(100%-280px)]">
            <h1 className="text-[30px] font-medium text-nevyblue mb-[23px]">
              My Schedule
            </h1>

            <Formik>
              <Form>
                <div className="flex justify-between items-center gap-[18px] ">
                  <div className="flex sm:gap-[27px] gap-[18px]">
                    <div className="">
                      <label className="text-[16px] font-normal text-black ">
                        Select Date
                      </label>
                      <div className="mt-[5px]">
                        <Listbox
                          className=""
                          value={selected}
                          onChange={(e) => {
                            setSelected(e);
                          }}
                        >
                          <div className="relative sm:w-[233px] w-[130px]">
                            <ListboxButton className="relative w-full p-[0px_16px] h-[42px] border border-[#ABAAAA] rounded-[4px] placeholder:text-[14px] placeholder:text-secondary focus:outline-none bg-white">
                              <span className="flex items-center">
                                <span className="block truncate text-secondary">
                                  {selected.name || "Select Date"}
                                </span>
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 ml-3 flex items-center pr-2 right-[10px]">
                                <IoIosArrowDown />
                              </span>
                            </ListboxButton>

                            <ListboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {Session.map((person) => (
                                <ListboxOption
                                  key={person.id}
                                  value={person}
                                  className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-primary data-[focus]:text-white"
                                >
                                  <div className="flex items-center">
                                    <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                                      {person.name}
                                    </span>
                                  </div>

                                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                    <CheckIcon
                                      aria-hidden="true"
                                      className="h-5 w-5"
                                    />
                                  </span>
                                </ListboxOption>
                              ))}
                            </ListboxOptions>
                          </div>
                        </Listbox>
                      </div>
                    </div>
                    <div className=" ">
                      <label className="text-[16px] font-normal text-black">
                        Time Zone
                      </label>
                      <div className="mt-[5px]">
                        <Listbox
                          className=""
                          value={selected1}
                          onChange={(e) => {
                            setSelected1(e);
                          }}
                        >
                          <div className="relative sm:w-[233px] w-[130px]">
                            <ListboxButton className="relative w-full p-[0px_16px] h-[42px] border border-[#ABAAAA] rounded-[4px] placeholder:text-[14px] placeholder:text-secondary focus:outline-none bg-white">
                              <span className="flex items-center">
                                <span className="block truncate text-secondary">
                                  {selected1.name || "Select Date"}
                                </span>
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 ml-3 flex items-center pr-2 right-[10px]">
                                <IoIosArrowDown />
                              </span>
                            </ListboxButton>

                            <ListboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {Session.map((person) => (
                                <ListboxOption
                                  key={person.id}
                                  value={person}
                                  className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-primary data-[focus]:text-white"
                                >
                                  <div className="flex items-center">
                                    <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                                      {person.name}
                                    </span>
                                  </div>

                                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                    <CheckIcon
                                      aria-hidden="true"
                                      className="h-5 w-5"
                                    />
                                  </span>
                                </ListboxOption>
                              ))}
                            </ListboxOptions>
                          </div>
                        </Listbox>
                      </div>
                    </div>
                  </div>

                  <Link
                    to={"/create-course"}
                    className="text-[18px] font-normal text-white w-[201px] h-[48px] flex justify-center items-center bg-primary"
                  >
                    Setup a New Class
                  </Link>
                </div>
              </Form>
            </Formik>
            <div className="mt-[33px]">
              <MyCalendar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MySchedule;
