import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOnline: "",
    contactNumber: "",
    role: "",
    firstName: '',
    lastName: '',
    email: "",
    source: '',
    subscription: '',
    availability: '',
    startTime: '',
    endTime: '',
    duration: '',
    couponCode: '',
    skills: [],
    profilePicture: {},
    skillMatrix: [],
    profileImageUrl: ""
};

const teacherProfileSlice = createSlice({
    name: 'teacherProfile',
    initialState,
    reducers: {
        updateTeacherProfile: (state, action) => {
            return { ...state, ...action.payload };
        },
        updateTeacherSkillMatrix: (state, action) => {
            state.skillMatrix = action.payload;
        },
    },
});

export const { updateTeacherProfile, updateTeacherSkillMatrix } = teacherProfileSlice.actions;
export default teacherProfileSlice.reducer;
